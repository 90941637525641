import {NavigationGuardNext, RouteLocationNormalized, createRouter, createWebHistory} from 'vue-router';
import AuthMiddleware from '@/router/middleware/auth';
import GuestMiddleware from '@/router/middleware/guest';
import InvitationsMiddleware from '@/router/middleware/invitations';
import NeedsTeamMiddleware from '@/router/middleware/needsTeam';
import routes from '@/router/routes';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => new AuthMiddleware().verifyRequirement(to, from, next));
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => new GuestMiddleware().verifyRequirement(to, from, next));
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => new InvitationsMiddleware().verifyRequirement(to, from, next));
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => new NeedsTeamMiddleware().verifyRequirement(to, from, next));

export default router;
