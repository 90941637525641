
import {defineComponent, ref} from 'vue';
import Body from '@/components/comments/Body.vue';
import CreateForm from '@/components/comments/CreateForm.vue';
import emitter from '@/vendor/mitt';
import store from '@/store/pitches/edit';

export default defineComponent({
    components: {
        Body,
        CreateForm,
    },
    setup() {
        return {
            comment: store.currentComment,
            replyFocused: ref<boolean>(false),
        };
    },
    methods: {
        removeCommentHighlight() {
            emitter.emit('removeCommentHighlight');

            store.setCurrentCommentUuid(undefined);
        },
    },
});
