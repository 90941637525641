import Resource, {Resources} from '@/models/Resource';
import Subject, {Subjects} from '@/models/Subject';
import Cast from '@/library/model-collection/casts/Cast';
import Collection from '@/models/Collection';
import ConstructorCast from '@/library/model-collection/casts/ConstructorCast';
import {DefaultConversions} from '@/models/Media';
import Model from '@/models/Model';
import ResourceType from '@/library/enumerations/ResourceType';
import User from '@/models/User';
import {uniqBy} from 'lodash';

interface TemplateData {
    author?: User;
    banner?: DefaultConversions;
    body?: string;
    conclusion?: string;
    createdAt?: string;
    hoverImage?: DefaultConversions;
    id?: number;
    introduction?: string;
    isPublic?: boolean;
    name: string;
    quote?: string;
    resources: Resources;
    slug?: string;
    subjects?: Subjects;
    updatedAt?: string;
    videosCount?: number;
}

class Template extends Model<TemplateData> {
    /**
     * @inheritdoc
     */
    endpoint = 'templates';

    /**
     * @inheritdoc
     */
    primaryKey: keyof TemplateData = 'slug';

    // Get all the media type resources from the subjects.
    get mediaResources(): Resources {
        const resources = this.resources.filter((resource: Resource) => resource.type === ResourceType.MEDIA);

        if (!this.subjects) {
            return resources;
        }

        resources.push(...this.subjects.reduce((resources: Resources, subject: Subject) => {
            resources.push(...subject.mediaResources.items);

            return resources;
        }, new Resources()));

        return new Resources(uniqBy(resources.items, 'id'));
    }

    /**
     * @inheritdoc
     */
    getCasts(): Partial<Record<keyof TemplateData, Cast>> {
        return {
            author: new ConstructorCast(User),
            subjects: new ConstructorCast(Subjects),
            resources: new ConstructorCast(Resources),
        };
    }

    getDefaults(): TemplateData {
        return {
            name: '',
            resources: new Resources(),
        };
    }
}

interface Template extends TemplateData {}

export default Template;

export class Templates extends Collection<Template> {
    /**
     * @inheritdoc
     */
    endpoint = 'templates';

    /**
     * @inheritdoc
     */
    getModel(): typeof Template {
        return Template;
    }
}
