
import {defineComponent, ref} from 'vue';
import debounce from 'lodash/debounce';
import {firstScrollingParent} from '@/library/helpers';

export default defineComponent({
    props: {
        commentUuid: {
            type: String,
            default: undefined,
        },
        // Add extra offset in case the slot component has padding/margin.
        extraOffset: {
            type: Number,
            default: -16,
        },
    },
    setup() {
        return {
            commentWrapper: ref<HTMLDivElement>(),
        };
    },
    watch: {
        commentUuid() {
            this.updateCommentPosition();
        },
    },
    mounted() {
        this.debounceFunction = debounce(this.debounceFunction, 500);

        window.addEventListener('resize', this.debounceFunction);

        this.updateCommentPosition();
    },
    beforeUnmount() {
        if (!this.debounceFunction) {
            return;
        }

        window.removeEventListener('resize', this.debounceFunction);
    },
    methods: {
        debounceFunction() {
            this.updateCommentPosition();
        },
        updateCommentPosition() {
            const highlightBlot = document.querySelector(`.comment-highlight[data-uuid*="${this.commentUuid}"]`);

            if (!highlightBlot || !this.commentWrapper) {
                return;
            }

            // Get the top positions of the highlight blot and the comment wrapper.
            const {top: blotTop} = highlightBlot.getBoundingClientRect();
            const {top: commentTop} = this.commentWrapper.getBoundingClientRect();

            // Get the scroll offset of the first scrolling parent of the wrapper.
            const scrollWrapper = firstScrollingParent(this.commentWrapper);

            const scrollTop = scrollWrapper
                ? scrollWrapper.scrollTop
                : 0;

            // Set the comment wrapper top padding to match the blot top position.
            this.commentWrapper.style.paddingTop = `${Math.max(blotTop - commentTop - scrollTop + this.extraOffset, 0)}px`;

            scrollWrapper.scroll({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
});
