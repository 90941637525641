
import DropdownMenu, {DropdownOption} from '@/components/common/DropdownMenu.vue';
import {PropType, defineComponent} from 'vue';
import omit from 'lodash/omit';

/**
 * Shows a Bootstrap dropdown component.
 * The dropdown toggle button is created by default with the passed title but
 * can be overridden by using the dropdown-toggle slot.
 * The passed options will be displayed in a list with optional slots to append
 * or prepend this list.
 * A slot for when the list is empty is also available, by default it will show
 * text that the dropdown has no options.
 */
export default defineComponent({
    components: {
        DropdownMenu,
    },
    props: {
        align: {
            type: String,
            default: 'start',
            validator: (value: string) => ['start', 'end'].includes(value),
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        itemClass: {
            type: [String, Array, Object],
            default: null,
        },
        menuClass: {
            type: [String, Array, Object],
            default: '',
        },
        options: {
            type: Array as PropType<DropdownOption[]>,
            default: () => [],
        },
        size: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: null,
        },
        variant: {
            type: String,
            default: 'outline-light',
        },
    },
    computed: {
        itemClasses(): string[] {
            return [
                this.size ? `btn-${this.size}` : '',
                this.variant ? `btn-${this.variant}` : '',
                this.fullWidth ? 'w-100' : '',
                this.itemClass as any,
            ];
        },
        menuAttrs(): Record<string, any> {
            /*
             * The $attrs also contains the classes and styling for the root
             * component which we don't want to pass along to the menu.
             */
            return omit(this.$attrs, [
                'class',
                'style',
            ]);
        },
    },
});
