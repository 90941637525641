
import Media, {MediaData} from '@/models/Media';
import {PropType, defineComponent, ref} from 'vue';
import Resource, {Resources} from '@/models/Resource';
import ResourceType from '@/library/enumerations/ResourceType';

export default defineComponent({
    props: {
        border: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: null,
        },
        mediaProperty: {
            type: String as PropType<keyof MediaData>,
            default: undefined,
        },
        resource: {
            type: Object as PropType<Resource>,
            required: true,
            validate: (r: Resources) => !r.where('type', ResourceType.MEDIA).isEmpty(),
        },
    },
    setup(props) {
        const firstFileMedia = props.resource.media.firstWhere('collectionName', 'files');

        return {
            media: ref<Media>(firstFileMedia),
        };
    },
    computed: {
        label(): string | MediaData[keyof MediaData] {
            if (this.mediaProperty === 'duration') {
                return this.resource.formattedMediaDuration;
            }

            return this.media.getAttribute(this.mediaProperty!);
        },
        mediaPropertyExists(): boolean {
            if (!this.mediaProperty || !this.media) {
                return false;
            }

            return this.media[this.mediaProperty] !== undefined;
        },
        thumbnail(): string|undefined {
            let {thumbnail} = this.media;

            const customThumbnail = this.resource.media.firstWhere('collectionName', 'thumbnail');

            if (customThumbnail) {
                thumbnail = customThumbnail.url;
            }

            return thumbnail;
        },
    },
});
