import {App} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Spinner from '@/components/common/Spinner.vue';
import WaitForResource from '@/components/common/model-collection/WaitForResource.vue';

export default {
    install(app: App): void {
        app
            .component('Fa', FontAwesomeIcon)
            .component('Spinner', Spinner)
            .component('WaitForResource', WaitForResource);
    },
};
