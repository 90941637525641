import Team, {Teams} from '@/models/Team';
import {computed, ref, watch} from 'vue';

/*
 * ----------------------------------------------------------------------------
 * - State --------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

/**
 * The first team of the user.
 */
const currentTeam = computed<Team|null>(() => teams.value.first());

/**
 * All the user's teams.
 */
const teams = ref(new Teams());

/**
 * Indicates whether the teams are empty.
 */
const teamsIsEmpty = computed<boolean>(() => teams.value.isEmpty());

/**
 * The program of the current team.
 */
const teamProgram = ref(new Team());

/*
 * ----------------------------------------------------------------------------
 * - Methods ------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

/**
 * Fetches the user's teams.
 */
const fetchTeams = (): Promise<void> => {
    return teams.value.fetch({url: '/users/me/teams'});
};

/**
 * Resets the state variables.
 */
const resetState = (): void => {
    teams.value = new Teams();
    teamProgram.value = new Team();
};

/*
 * ----------------------------------------------------------------------------
 * - Watchers -----------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

/**
 * When the current team changes, the team should be re-fetched to get the program.
 */
watch(currentTeam, async (newValue?: Team|null) => {
    if (newValue == null) {
        teamProgram.value = new Team();

        return;
    }

    await newValue.fetch();

    if (!newValue.programs || !newValue.programs.count()) {
        return;
    }

    teamProgram.value = newValue.programs.first();
});

export default {
    currentTeam,
    teams,
    teamsIsEmpty,
    teamProgram,

    fetchTeams,
    resetState,
};
