import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    key: _ctx.newComment.id,
    "input-class": "form-control-plain",
    "reference-comment": _ctx.newComment,
    onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.newComment.body = '')),
    onSubmit: _ctx.resetCommentForm
  }, null, 8, ["reference-comment", "onSubmit"]))
}