import {NavigationGuardNext, RouteLocationNormalized} from 'vue-router';

export default class Middleware {
    public name: string;

    constructor() {
        this.name = '';
    }

    /*
     * Handle the request.
     */
    verifyRequirement(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
        if (this.needsToHandle(to)) {
            return this.handle(to, from, next);
        }

        return next();
    }

    needsToHandle(route: RouteLocationNormalized): boolean {
        if (!route.meta.middleware || !(route.meta.middleware instanceof Array)) return false;
        return route.meta.middleware.includes(this.name);
    }

    handle(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
        return next();
    }
}
