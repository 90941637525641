
import {PropType, defineComponent} from 'vue';
import Base from '@/library/model-collection/Base';
import StatusError from '@/components/common/StatusError.vue';
import get from 'lodash/get';

/**
 * Shows loading and error state of a resource.
 * By default, it displays a loader if the passed resource is being fetched.
 * If the resource gets an error, the component will display the status error.
 * The property on which the loader should be shown is 'fetching' by default.
 */
export default defineComponent({
    components: {
        StatusError,
    },
    props: {
        class: {
            type: String,
            default: '',
        },
        hideError: {
            type: Boolean,
            default: false,
        },
        loadingProperty: {
            type: String,
            default: 'fetching',
        },
        resource: {
            type: Object as PropType<Base<any>>,
            required: true,
        },
        showErrorDetails: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes(): string {
            return this.class;
        },
        isLoading(): boolean {
            return get(this.resource, this.loadingProperty);
        },
        hasError(): boolean {
            return !this.hideError && this.resource.error;
        },
    },
});
