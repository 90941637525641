import Collection, {CollectionData} from '@/models/Collection';
import Cast from '@/library/model-collection/casts/Cast';
import ConstructorCast from '@/library/model-collection/casts/ConstructorCast';
import Model from '@/models/Model';
import {Pitches} from '@/models/Pitch';
import Template from '@/models/Template';
import pick from 'lodash/pick';

export interface PitchMomentData {
    id?: number;
    name: string;
    teamId: number;
    templateId: number;
    template?: Template;
    deadlineAt: string;
    presentationAt: string;
    createdAt?: string;
    updatedAt?: string;
    pitches?: Pitches;
    pitchesCount?: number;
}

class PitchMoment extends Model<PitchMomentData> {
    /**
     * @inheritdoc
     */
    endpoint = 'pitch-moments';

    /**
     * @inheritdoc
     */
    locationName = 'pitchMoments';

    /**
     * @inheritdoc
     */
    getCasts(): Partial<Record<keyof PitchMomentData, Cast>> {
        return {
            template: new ConstructorCast(Template),
            pitches: new ConstructorCast(Pitches),
        };
    }

    /**
     * @inheritdoc
     */
    getDefaults(): PitchMomentData {
        return {
            name: '',
            teamId: 0,
            templateId: 0,
            deadlineAt: '',
            presentationAt: '',
        };
    }

    /**
     * @inheritdoc
     */
    getSaveData(): Partial<Record<string, any>> {
        const data = pick(
            this,
            [
                'name',
                'teamId',
                'templateId',
                'deadlineAt',
                'presentationAt',
            ],
        );

        if (
            data.templateId === 0
            && this.template?.id !== undefined
        ) {
            data.templateId = this.template.id;
        }

        return data;
    }
}

interface PitchMoment extends PitchMomentData {}

export default PitchMoment;

interface PitchMomentCollectionData extends CollectionData {
    pitchTeam?: number;
}

export class PitchMoments extends Collection<PitchMoment, PitchMomentCollectionData> {
    /**
     * @inheritdoc
     */
    endpoint = 'pitch-moments';

    /**
     * @inheritdoc
     */
    getModel(): typeof PitchMoment {
        return PitchMoment;
    }
}
