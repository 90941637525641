
import {defineComponent, ref} from 'vue';

/**
 * Creates an overflow collapse component.
 * It will display the content in the default slot but if it exceeds a certain
 * set max height, the overflow will be hidden and a button will be shown to
 * toggle the max height.
 * If the overflow toggle is clicked to show all, the max height is ignored on
 * the component and the overflow is visible.
 */
export default defineComponent({
    props: {
        /*
         * Define how many lines of text will be shown before the collapse toggle
         * is shown.
         */
        maxLines: {
            type: Number,
            default: 8,
            validator: (value: number) => value > 0,
        },
    },
    setup() {
        return {
            overflowCollapseContent: ref<HTMLDivElement>(),
            showCollapseToggle: ref<boolean>(false),
            open: ref<boolean>(false),
            slotObserver: ref<MutationObserver>(),
        };
    },
    computed: {
        collapseCssVariables(): Record<string, any> {
            return {
                '--overflow-collapse-max-lines': `${this.maxLines}rem`,
            };
        },
    },
    mounted() {
        if (!this.overflowCollapseContent) {
            return;
        }

        /*
         * Create an observer to observe changes in the content slot so we can
         * trigger the setCollapse method again.
         */
        this.slotObserver = new MutationObserver(() => {
            this.open = false;

            this.setCollapse();
        });

        this.slotObserver.observe(
            this.overflowCollapseContent,
            {
                characterData: true,
                subtree: true,
            },
        );

        this.setCollapse();
    },
    beforeUnmount() {
        if (this.slotObserver) {
            this.slotObserver.disconnect();
        }
    },
    methods: {
        setCollapse() {
            if (!this.overflowCollapseContent) {
                return;
            }

            // Get the max height style value as a string.
            const maxHeightString = getComputedStyle(this.overflowCollapseContent).getPropertyValue('max-height');
            let maxHeightPx = parseInt(maxHeightString, 10);

            // If the max height string is in rem, multiply it by the font size.
            if (/rem/.test(maxHeightString)) {
                // Get the font size from the document element in pixels.
                const fontSize = getComputedStyle(document.documentElement).getPropertyValue('font-size');

                maxHeightPx *= parseInt(fontSize, 10);
            }

            // If the collapse content height is equal to the max height, show the toggle.
            this.showCollapseToggle = maxHeightPx === this.overflowCollapseContent.offsetHeight;
        },
    },
});
