import 'pusher-js';
import Echo from 'laravel-echo';
import {HasWebsocket} from '@/models/mixins/HasWebsocket';
import {PusherPresenceChannel} from 'laravel-echo/src/channel/pusher-presence-channel';
import {WEBSOCKET_OPTIONS} from '@/library/data/config';
import auth from '@/store/auth';
import get from 'lodash/get';

class Websocket {
    /**
     * The channel that a model sends and receives messages on.
     */
    channel?: PusherPresenceChannel;

    /**
     * The global websocket connection.
     * This will handle all incoming and outgoing messages for all channels.
     */
    protected static connection?: Echo;

    /**
     * If there is a channel name create a connection, if it doesn't exist,
     * join the channel and register potential listeners.
     */
    constructor(model: HasWebsocket) {
        if (!Websocket.connection && auth.state.apiToken) {
            Websocket.connection = new Echo({
                ...WEBSOCKET_OPTIONS,
                auth: {
                    headers: {
                        Accept: 'application/json, text/plain, */*',
                        Authorization: `Bearer ${auth.state.apiToken}`,
                    },
                },
            });
        }

        this.join(model);
    }

    /**
     * Returns the users that are currently in the channel.
     */
    get users(): Record<string, any>[] {
        const members = get(this, 'channel.subscription.members.members', {});

        return Object.values(members);
    }

    /**
     * Get the socketID from the current connection.
     */
    static getSocketId(): string|undefined {
        return this.connection
            ? this.connection.socketId()
            : undefined;
    }

    /**
     * Joins the channel if getChannelName returns something.
     */
    join(model: HasWebsocket): void {
        if (!Websocket.connection) {
            return;
        }

        const channelName = model.getChannelName();

        if (!channelName) {
            return;
        }

        this.channel = Websocket.connection!
            .join(channelName) as PusherPresenceChannel;

        model.registerListeners(this.channel!);
    }

    /**
     * Leaves the channel of the model.
     */
    leave(): void {
        if (!Websocket.connection || !this.channel) {
            return;
        }

        Websocket.connection.leaveChannel(this.channel.name);

        this.channel = undefined;
    }

    /**
     * Adds a listener to the websocket.
     */
    listen(event: string, callback: {(...args: any[]): void}): void {
        if (!this.channel) {
            return;
        }

        this.channel.listen(event, callback);
    }

    /**
     * Adds a whisper listener to the websocket.
     */
    listenForWhisper(event: string, callback: {(...args: any[]): void}): void {
        if (!this.channel) {
            return;
        }

        this.channel.listenForWhisper(event, callback);
    }

    /**
     * Calls the whisper method on the channel.
     */
    sendMessage(event: string, data: Record<string, any> = {}): void {
        if (!this.channel) {
            return;
        }

        this.channel.whisper(event, data);
    }
}

export default Websocket;
