import Enumeration, {Enum} from '@/library/enumerations/Enumeration';
import Comment from '@/models/Comment';
import Media from '@/models/Media';
import Script from '@/models/Script';

@Enum
export default class CommentableType extends Enumeration {
    static COMMENTS = 'comments';
    static MEDIA = 'media';
    static SCRIPTS = 'pitch_scripts';

    static getModelType(type: CommentableType): typeof Comment|typeof Media|typeof Script {
        switch (type) {
            case CommentableType.SCRIPTS:
                return Script;
            case CommentableType.MEDIA:
                return Media;
            default:
                return Comment;
        }
    }
}
