import {RouteRecordRaw} from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/pitches/:slug',
        name: 'pitches.edit',
        component: () => import('@/views/pitches/Edit.vue'),
        props: true,
    },
];

export default routes;
