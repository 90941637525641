
import {PropType, defineComponent} from 'vue';
import Markdown from '@/components/common/Markdown.vue';
import Resource from '@/models/Resource';

export default defineComponent({
    components: {
        Markdown,
    },
    props: {
        tip: {
            type: Object as PropType<Resource>,
            required: true,
        },
    },
});
