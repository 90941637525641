import Collection from '@/models/Collection';
import Model from '@/models/Model';
import TagType from '@/library/enumerations/TagType';

export interface TagData {
    id?: number;
    name: string;
    type?: TagType;
}

class Tag extends Model<TagData> {
    /**
     * @inheritdoc
     */
    endpoint = 'tags';

    /**
     * @inheritdoc
     */
    getDefaults(): TagData {
        return {
            name: '',
        };
    }
}

interface Tag extends TagData {}

export default Tag;

export class Tags extends Collection<Tag> {
    /**
     * @inheritdoc
     */
    endpoint = 'tags';

    /**
     * @inheritdoc
     */
    getModel(): typeof Tag {
        return Tag;
    }
}
