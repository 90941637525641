import {RouteRecordRaw} from 'vue-router';
import registerTeamRoutes from '@/router/routes/registerTeams';

const children: RouteRecordRaw[] = [
    ...registerTeamRoutes,
];

const routes: RouteRecordRaw[] = [
    {
        path: '',
        component: () => import('@/components/layouts/auth/Auth.vue'),
        children,
    },
    {
        path: 'teams',
        component: () => import('@/views/Index.vue'),
        children: [
            {
                path: '',
                name: 'teams.index',
                component: () => import('@/views/teams/Index.vue'),
            },
            {
                path: ':id/edit',
                name: 'teams.edit',
                component: () => import('@/views/teams/Edit.vue'),
                props: true,
            },
        ],
        props: true,
        meta: {
            middleware: [
                'Auth',
                'NeedsTeam',
            ],
            navbarBorder: true,
        },
    },
];

export default routes;
