
import {PropType, defineComponent, ref} from 'vue';
import Comment from '@/models/Comment';
import CommentableType from '@/library/enumerations/CommentableType';
import Form from '@/components/comments/Form.vue';
import Script from '@/models/Script';
import store from '@/store/pitches/edit';

export default defineComponent({
    components: {
        Form,
    },
    props: {
        commentable: {
            type: Object as PropType<Comment|Script>,
            required: true,
        },
        uuid: {
            type: String,
            default: null,
        },
    },
    emits: [
        'submit',
    ],
    setup(props) {
        const scriptDelta = store.newCommentUpdate;

        const comment = new Comment({
            commentableType: scriptDelta.value
                ? CommentableType.SCRIPTS
                : CommentableType.COMMENTS,
            commentableId: props.commentable.id,
            scriptDelta: scriptDelta.value,
            ...(props.uuid && {uuid: props.uuid}),
        }, props.commentable.comments);

        return {
            newComment: ref<Comment>(comment),
        };
    },
    watch: {
        'commentable.id': function handler() {
            this.newComment.commentableId = this.commentable.id;
            this.newComment.setCollection(this.commentable.comments);
        },
    },
    methods: {
        async resetCommentForm(comment: Comment) {
            // Emit the newly created comment.
            this.$emit('submit', comment);

            // Reset the comment update in the store.
            store.setNewCommentUpdate();

            /*
             * Set the newComment to a new comment instance to lose reactivity
             * with the emitted comment.
             */
            this.newComment = new Comment({
                commentableType: CommentableType.COMMENTS,
                commentableId: this.commentable.id,
            }, this.commentable.comments);
        },
    },
});
