
import {PropType, defineComponent} from 'vue';
import Errors from '@/library/model-collection/Errors';
import get from 'lodash/get';

/**
 * Displays error text with the invalid-feedback class.
 * Usable for anything Form input related.
 */
export default defineComponent({
    props: {
        errors: {
            type: Object as PropType<Errors>,
            default: () => ({}),
        },
        errorKey: {
            type: [String, Array],
            default: null,
        },
    },
    computed: {
        error(): string|undefined {
            if (!this.errors || !this.errors.errors) {
                return undefined;
            }

            if (!this.errorKey) {
                const keys = Object.keys(this.errors.errors);

                return keys.length
                    ? get(this.errors, ['errors', keys[0], '0'])
                    : undefined;
            }

            const errorKeys = Array.isArray(this.errorKey)
                ? this.errorKey as string[]
                : [this.errorKey];

            for (const key of errorKeys) {
                const errorString = get(
                    this.errors,
                    ['errors', key, '0'],
                );

                if (errorString) {
                    return errorString;
                }
            }

            return undefined;
        },
    },
});
