import {RouteRecordRaw} from 'vue-router';
import appRoutes from '@/router/routes/app';
import authRoutes from '@/router/routes/auth';
import demoRoutes from '@/router/routes/demo';
import invitationRoutes from '@/router/routes/invitations';

const routes: RouteRecordRaw[] = [
    ...appRoutes,
    ...authRoutes,
    ...demoRoutes,
    ...invitationRoutes,
];

if (process.env.NODE_ENV !== 'production') {
    routes.push({
        path: '/styleguide',
        component: () => import('@/views/styleguide/Index.vue'),
    });
}

/*
 * This should always be the last route, because all routes
 * declared after this one won't work.
 */
routes.push({
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: () => import('@/views/PageNotFound.vue'),
});

export default routes;
