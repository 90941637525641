import {LocationQueryRaw, RouteLocationRaw} from 'vue-router';

// Used for type hinting
export type LocationName = 'index' | 'show' | 'edit' | 'create' | string;

type Constructor = new (...args: any[]) => {
    endpoint: string;
    primaryKey: string;
    getIdentifier: () => string;
}

export interface HasLocation {
    locationName: string;

    getLocation(name?: LocationName, query?: LocationQueryRaw): RouteLocationRaw;

    getLocationName(): string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const HasLocationMixin = <T extends Constructor>(Base: T) => {
    return class HasLocation extends Base {
        /**
         * The location name that the model uses.
         */
        locationName?: string;

        /**
         * Returns a specific vue-router location for this model.
         * By default, the 'index' location will be returned.
         */
        getLocation(name: LocationName = 'edit', query?: LocationQueryRaw): RouteLocationRaw {
            const location: RouteLocationRaw = {
                name: `${this.getLocationName()}.${name}`,
                query,
            };

            // The identifier should be used if the location is not 'index' or 'create'.
            if (!['index', 'create'].includes(name)) {
                location.params = {
                    [this.primaryKey]: this.getIdentifier(),
                };
            }

            return location;
        }

        /**
         * Returns the name of the location for the model.
         */
        getLocationName(): string {
            return this.locationName || this.endpoint;
        }
    };
};

export default HasLocationMixin;
