import Enumeration, {Enum} from '@/library/enumerations/Enumeration';

@Enum
export default class SharePermission extends Enumeration {
    static translationKey = 'sharePermissions';

    static VIEW = 1;
    static COMMENT = 2;
    static EDIT = 3;

    static canView(permission: number): boolean {
        return [
            SharePermission.VIEW,
            SharePermission.COMMENT,
            SharePermission.EDIT,
        ].includes(permission);
    }

    static canComment(permission: number): boolean {
        return [
            SharePermission.COMMENT,
            SharePermission.EDIT,
        ].includes(permission);
    }

    static canEdit(permission: number): boolean {
        return [
            SharePermission.EDIT,
        ].includes(permission);
    }
}
