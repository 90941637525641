/* eslint-disable import/prefer-default-export */
import {Modal} from 'bootstrap';

// Try and get the Modal instance of a DOM element by the given ID.
const getModal = (id: string): Modal | null => {
    const modalEl = document.getElementById(id);

    if (!modalEl) return null;

    return Modal.getOrCreateInstance(modalEl);
};

// It gets a bootstrap Modal instance and hides it.
const hideModal = (id: string): void => {
    const modal = getModal(id);

    if (!modal) return;

    modal.hide();
};

// It gets a bootstrap Modal instance and shows it.
const showModal = (id: string): void => {
    const modal = getModal(id);

    if (!modal) return;

    modal.show();
};

export {
    getModal,
    hideModal,
    showModal,
};
