import BaseCollection from '@/library/model-collection/Collection';
import HasHttpProvider from '@/library/model-collection/providers/HasHttpProvider';
import HttpProvider from '@/library/model-collection/providers/HttpProvider';

export interface CollectionData {
    /*
     * The API requires `filter` instead of `filters`. However this results in
     * a conflict with the `filter` method of `Collection`.
     */
    filters?: Record<string, any>;
    include?: string[];
    page?: number;
    sorts?: string;
    perPage?: number;
}

abstract class Collection<M, A extends CollectionData = CollectionData> extends BaseCollection<M, A> implements HasHttpProvider {
    /**
     * @inheritdoc
     */
    abstract endpoint: string;

    /**
     * @inheritdoc
     */
    provider = new HttpProvider();

    lastPage: number = Infinity;

    // The total number of paginated results.
    total: number = 0;

    addFilter(attribute: string, value: string|number|boolean): void {
        if (!this.filters) {
            this.set('filters', {});
        }

        this.filters![attribute] = value;
    }

    applyPagination(items: any[]): void {
        /*
         * If the fetched items array isn't empty, increment the page and merge
         * the items into the collection.
         */
        if (items.length) {
            this.push(...this.mapItems(items));

            this.page!++;
        } else {
            /*
             * Otherwise set the page to the LAST_PAGE value so we know not to
             * make any more fetch requests.
             */
            this.page = this.lastPage;
        }
    }

    /**
     * @inheritdoc
     */
    cancel(message?: string): void {
        this.provider.cancel(message);
    }

    /**
     * @inheritdoc
     */
    clear(): void {
        super.clear();

        this.page = this.isPaginated() ? 1 : undefined;
        this.lastPage = Infinity;
    }

    /**
     * @inheritdoc
     */
    getDefaults(): CollectionData {
        return {
            page: 1,
        };
    }

    /**
     * @inheritdoc
     */
    getEndpoint(): string {
        return this.endpoint;
    }

    /**
     * @inheritdoc
     */
    getFetchParams(): Record<string, any> {
        const attributes = {...this.attributes} as Record<string, any>;

        if ('filters' in attributes) {
            attributes.filter = attributes.filters;

            delete attributes.filters;
        }

        if ('include' in attributes) {
            attributes.include = attributes.include.join();
        }

        if ('sorts' in attributes) {
            attributes.sort = attributes.sorts;

            delete attributes.sorts;
        }

        if (!this.isPaginated()) {
            delete attributes.page;
        }

        return attributes;
    }

    /**
     * @inheritdoc
     */
    getSaveData(): Record<string, any>[] {
        return [];
    }

    /**
     * Check if the filters has a specific attribute.
     */
    hasFilter(attribute: string): boolean {
        return this.filters != null
            && this.filters[attribute] != null;
    }

    isPaginated(): boolean {
        return this.page !== undefined;
    }

    /**
     * Returns a boolean whether the last page of the paginated data has been
     * fetched already.
     */
    lastPageFetched(): boolean {
        return this.isPaginated()
            && this.page! >= this.lastPage;
    }

    /**
     * @inheritdoc
     */
    onFetchSuccess(result: Record<string, any>): void {
        if (!result) {
            return;
        }

        if (this.isPaginated()) {
            this.lastPage = result.lastPage + 1;
            this.total = result.total;

            this.applyPagination(result.data);
        } else {
            super.onFetchSuccess(result);
        }
    }

    /**
     * Remove one specific filter attribute.
     */
    removeFilter(attribute: string): void {
        if (!this.filters) {
            return;
        }

        delete this.filters[attribute];
    }

    /**
     * Set the sorts attribute.
     */
    setSort(value: string): void {
        if (!this.sorts) {
            this.registerAttribute('sorts');
        }

        this.sorts = value;
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Collection<M, A = CollectionData> extends CollectionData {}

export default Collection;
