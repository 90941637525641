import Quill from 'quill';
import emitter from '@/vendor/mitt';
import store from '@/store/pitches/edit';

const Inline = Quill.import('blots/inline');

class HighlightBlot extends Inline {
    static create(uuid: string): HTMLElement {
        const node = super.create();

        node.setAttribute('class', 'comment-highlight');
        node.setAttribute('data-uuid', uuid);
        node.addEventListener('click', () => {
            /*
             * The split is needed to get the first uuid when there are multiple
             * comments on a single highlight.
             */
            store.setCurrentCommentUuid(uuid.split(',')[0]);
        });

        return node;
    }

    deleteAt(index: number, length: number): void {
        super.deleteAt(index, length);

        // If the highlight blot is fully deleted, possibly remove the comment.
        if (this.length() === 0) {
            emitter.emit('highlightBlotDeleted', this.formats().highlight);
        }
    }

    static formats(node: HTMLElement): string | null {
        return node.getAttribute('data-uuid');
    }
}

HighlightBlot.blotName = 'highlight';
HighlightBlot.tagName = 'span';

export default HighlightBlot;
