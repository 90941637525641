// eslint-disable-file no-use-before-define

import Cast from '@/library/model-collection/casts/Cast';
import Collection from '@/models/Collection';
import CommentableType from '@/library/enumerations/CommentableType';
import ConstructorCast from '@/library/model-collection/casts/ConstructorCast';
import Delta from 'quill-delta';
import Media from '@/models/Media';
import Model from '@/models/Model';
import Script from '@/models/Script';
import User from '@/models/User';
import pick from 'lodash/pick';
import {v4} from 'uuid';

interface CommentData {
    id?: number;
    uuid: string;
    commentableType: CommentableType;
    commentableId?: number;
    userId?: number;
    body: string;
    createdAt?: string;
    updatedAt?: string;
    user?: User;
    commentable?: Comment|Media|Script;
    comments: Comments;
    /*
     * This is not an actual attribute of the comment but it's needed for the
     * create request of a comment.
     */
    scriptDelta?: Delta;
}

class Comment extends Model<CommentData> {
    /**
     * @inheritdoc
     */
    endpoint = 'comments';

    /**
     * @inheritdoc
     */
    getCasts(): Partial<Record<keyof CommentData, Cast>> {
        return {
            commentable: new ConstructorCast(CommentableType.getModelType(this.commentableType)),
            comments: new ConstructorCast(Comments),
            scriptDelta: new ConstructorCast(Delta),
            user: new ConstructorCast(User),
        };
    }

    /**
     * @inheritdoc
     */
    getDefaults(): CommentData {
        return {
            body: '',
            uuid: v4(),
            comments: new Comments(),
            commentableType: CommentableType.SCRIPTS,
        };
    }

    /**
     * @inheritdoc
     */
    getSaveData(): Partial<Record<string, any>> {
        return pick(
            this,
            [
                'body',
                'commentableType',
                'commentableId',
                'uuid',
                'scriptDelta',
            ],
        );
    }
}

interface Comment extends CommentData {}

export default Comment;

export class Comments extends Collection<Comment> {
    /**
     * @inheritdoc
     */
    endpoint = 'comments';

    /**
     * @inheritdoc
     */
    getModel(): typeof Comment {
        return Comment;
    }
}
