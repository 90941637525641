import {PusherPresenceChannel} from 'laravel-echo/src/channel/pusher-presence-channel';
import Websocket from '@/library/websockets/Websocket';
import {reactive} from 'vue';

type Constructor = new (...args: any[]) => {
    fill(attributes: Record<string, any>): void;
}

export interface HasWebsocket {
    websocket: Websocket;

    getChannelName: () => string|undefined;

    registerListeners: (channel: PusherPresenceChannel) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const HasWebsocketMixin = <T extends Constructor>(Base: T) => {
    abstract class HasWebsocket extends Base {
        /**
         * The websocket class that will be used to subscribe, send and receive messages and
         * other websocket related things.
         */
        websocket: Websocket;

        constructor(...args: any[]) {
            super(...args);

            /*
             * Make the model reactive. If this is not done, the registered listeners
             * will edit the model directly, so vue can't watch changes being made.
             */
            this.websocket = new Websocket(reactive(this) as HasWebsocket);
        }

        /**
         * @inheritdoc
         */
        fill(attributes: Record<string, any>): void {
            super.fill(attributes);

            /*
             * If the model is connected to a channel and it's identifier changes,
             * it should leave the old channel and join a new channel with the new identifier.
             */
            if (this.websocket.channel) {
                // Return if the name hasn't changed.
                if (this.websocket.channel.name === `presence-${this.getChannelName()}`) {
                    return;
                }

                this.websocket.leave();

                this.websocket.channel = undefined;
            }

            this.websocket.join(this);
        }

        /**
         * Returns the name that should be used to make a connection. Returns undefined
         * if there shouldn't be a connection.
         */
        abstract getChannelName: () => string|undefined;

        /**
         * Registers the listeners that the channel should listen to.
         */
        abstract registerListeners: (channel: PusherPresenceChannel) => void;
    }

    return HasWebsocket;
};

export default HasWebsocketMixin;
