
import {PropType, defineComponent, ref} from 'vue';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import Comment from '@/models/Comment';
import FormGroup from '@/components/common/form/FormGroup.vue';

export default defineComponent({
    components: {
        BtnResource,
        FormGroup,
    },
    props: {
        referenceComment: {
            type: Object as PropType<Comment>,
            required: true,
        },
        focusOnMount: {
            type: Boolean,
            default: false,
        },
        forceShowButtons: {
            type: Boolean,
            default: false,
        },
        inputClass: {
            type: [String, Array, Object],
            default: null,
        },
    },
    emits: [
        'blur',
        'cancel',
        'focus',
        'submit',
    ],
    setup(props) {
        return {
            comment: ref<Comment>(props.referenceComment),
            fieldBody: ref<HTMLTextAreaElement>(),
        };
    },
    mounted() {
        if (this.focusOnMount) {
            this.fieldBody!.focus();
        }
    },
    methods: {
        cancel() {
            this.$emit('cancel');

            this.loseFocus();
        },
        loseFocus() {
            // Remove the focus of the active element.
            const {activeElement} = document;

            if (activeElement) {
                (activeElement as HTMLElement).blur();
            }
        },
        async submit() {
            try {
                await this.comment.save();
            } catch (e: any) {
                if (e.response.status !== 422) {
                    throw e;
                }

                return;
            }

            this.$emit('submit', this.comment);

            this.loseFocus();
        },
    },
});
