
import {PropType, defineComponent, ref} from 'vue';
import Error from '@/components/common/Error.vue';
import Errors from '@/library/model-collection/Errors';

/**
 * Shows a Bootstrap form group component.
 * Has slots available to override the input field itself or append or prepend
 * elements to the input field.
 * Can optionally show help text to give instruction about the input field.
 * Emits 'update:modelValue' when the input value has changed.
 */
export default defineComponent({
    components: {
        Error,
    },
    props: {
        autofocus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errorKey: {
            type: [String, Array],
            default: null,
        },
        errors: {
            type: Object as PropType<Errors>,
            default: () => ({}),
        },
        helpText: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: null,
        },
        modelValue: {
            type: [String, Number],
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        noPrependStyle: {
            type: Boolean,
            default: false,
        },
        noAppendStyle: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: null,
            validator: (value: string) => ['sm', 'lg'].includes(value),
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
    },
    emits: [
        'update:modelValue',
    ],
    setup() {
        return {
            errorRef: ref<typeof Error>(),
        };
    },
    computed: {
        inputClass(): Record<string, any> {
            return {
                'no-prepend-style': this.noPrependStyle,
                'no-append-style': this.noAppendStyle,
                'is-invalid': this.errorRef?.error,
                'bg-transparent': this.transparent,
                'form-control-plain': this.readonly,
            };
        },
        inputName(): string | null {
            return this.name
                || this.label
                || null;
        },
        hasPrependContent(): boolean {
            return this.hasSlotContent('group-prepend');
        },
        hasAppendContent(): boolean {
            return this.hasSlotContent('group-append');
        },
    },
    methods: {
        hasSlotContent(slot: string): boolean {
            if (Object.prototype.hasOwnProperty.call(this.$slots, slot)) {
                return !!this.$slots[slot]!();
            }

            return false;
        },
    },
});
