import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "list-unstyled" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _component_ResourceListItem = _resolveComponent("ResourceListItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.resources.isEmpty())
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.currentResource)
            ? (_openBlock(), _createBlock(_component_VideoPlayer, {
                key: 0,
                class: "mb-4",
                "keep-dimensions-in-theatre": "",
                "play-on-src-change": !_ctx.paused,
                video: _ctx.video,
                onPause: _cache[0] || (_cache[0] = ($event: any) => (_ctx.paused = true)),
                onPlay: _cache[1] || (_cache[1] = ($event: any) => (_ctx.paused = false))
              }, null, 8, ["play-on-src-change", "video"]))
            : _createCommentVNode("", true),
          _createElementVNode("ul", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resources, (resource) => {
              return (_openBlock(), _createBlock(_component_ResourceListItem, {
                key: resource.id,
                border: "",
                class: _normalizeClass(["mb-4 p-4 cursor-pointer", {active: _ctx.currentResource === resource}]),
                "media-property": "duration",
                resource: resource,
                onClick: ($event: any) => (_ctx.currentResource = resource)
              }, null, 8, ["class", "resource", "onClick"]))
            }), 128))
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode(_toDisplayString(_ctx.$t('videos.noVideos')), 1)
        ], 64))
  ]))
}