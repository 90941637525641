import Collection from '@/models/Collection';
import Model from '@/models/Model';

export interface DefaultConversions {
    original: string;
    [key: string]: string;
}

export interface MediaData<C = DefaultConversions> {
    id?: number;
    fileName: string;
    collectionName: string;
    mimeType: string;
    size: number;
    url: string;
    thumbnail?: string;
    duration?: number;
    conversions?: C;
    dimensions?: {
        width: number;
        height: number;
    };
}

class Media extends Model<MediaData> {
    /**
     * @inheritdoc
     */
    endpoint = 'media';

    // Tries to find the passed conversion but will fall back to the original url.
    getConversion(size: string): string {
        return this.conversions !== undefined
            ? this.conversions[size] || this.conversions.original
            : this.url;
    }

    /**
     * @inheritdoc
     */
    getDefaults(): MediaData {
        return {
            fileName: '',
            collectionName: '',
            mimeType: '',
            size: 0,
            url: '',
        };
    }
}

interface Media extends MediaData {}

export default Media;

export class Medias extends Collection<Media> {
    /**
     * @inheritdoc
     */
    endpoint = 'media';

    /**
     * @inheritdoc
     */
    getModel(): typeof Media {
        return Media;
    }
}
