import {NavigationGuardNext, RouteLocationNormalized} from 'vue-router';
import Auth from '@/router/middleware/auth';
import Guest from '@/router/middleware/guest';
import Middleware from '@/library/Middleware';
import config from '@/library/data/config';

export default class Invitations extends Middleware {
    constructor() {
        super();

        this.name = 'Invitations';
    }

    async handle(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> {
        if (!to.query.token) {
            if (await new Auth().isAuthenticated()) {
                next();

                return;
            }

            /*
             * Here we know the user is not authenticated so we store the wanted route
             * and redirect the user to the login page.
             */
            sessionStorage.setItem(config.REDIRECT_AFTER_AUTH_KEY, JSON.stringify(to));

            next({
                name: 'auth.login',
                query: {
                    message: 'invitations.loginBeforeAccept',
                },
            });

            return;
        }
        await new Guest().handle(to, from, next);
    }
}
