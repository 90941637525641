import '@/vendor/axios';
import '@/vendor/theme';
import 'bootstrap';
import 'plyr/dist/plyr.css';
import App from '@/App.vue';
import FontAwesome from '@/vendor/FontAwesome';
import HighlightBlot from '@/library/quill/HighlightBlot';
import PortalVue from 'portal-vue';
import Quill from 'quill';
import QuillCursors from 'quill-cursors';
import components from '@/plugins/components';
import {createApp} from 'vue';
import directives from '@/plugins/directives';
import i18n from '@/vendor/I18n';
import router from '@/router';

FontAwesome.init();

Quill.register(HighlightBlot);

Quill.register('modules/cursors', QuillCursors);

createApp(App)
    .use(components)
    .use(directives)
    .use(router)
    .use(i18n)
    .use(PortalVue)
    .mount('#app');
