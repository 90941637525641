const config = {
    TITLE: {
        default: 'Pitchy',
        pwc: 'Pitchy x PwC',
        rabobank: 'Pitchy x Rabobank',
        sbc: 'Pitchy x Startupbootcamp',
        'scale-nl': 'Pitchy x ScaleNL',
    },
    // From: https://github.com/owowagency/monoa-ui/blob/develop/src/components/common/form/InputEmailsList.vue
    EMAIL_REGEX: '(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))',
    NAVBAR_COLLAPSE_KEY: 'navbar_collapsed',
    REDIRECT_AFTER_AUTH_KEY: 'redirect_after_auth',
    THEME_BUILDER_STYLESHEET_LINK_ID: 'theme-builder-style-sheet-link',
    WORDS_PER_MINUTE: 130,
};

export const WEBSOCKET_OPTIONS = {
    authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
    broadcaster: 'pusher',
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    forceTLS: true,
    key: process.env.VUE_APP_PUSHER_KEY,
    wsHost: process.env.VUE_APP_WS_HOST,
    wsPort: process.env.VUE_APP_WS_PORT,
    wssHost: process.env.VUE_APP_WS_HOST,
    wssPort: process.env.VUE_APP_WS_PORT,
};

export default config;
