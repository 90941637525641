import {App} from 'vue';
import infiniteScroll from '@/library/directives/infiniteScroll';
import infiniteScrollCollection from '@/library/directives/infiniteScrollCollection';
import tooltip from '@/library/directives/tooltip';

export default {
    install(app: App): void {
        app.directive('infinite-scroll', infiniteScroll);
        app.directive('infinite-scroll-collection', infiniteScrollCollection);
        app.directive('tooltip', tooltip);
    },
};
