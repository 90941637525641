
import {defineComponent, ref} from 'vue';
import Comment from '@/models/Comment';
import CommentPosition from '@/components/comments/CommentPosition.vue';
import CreateForm from '@/components/comments/CreateForm.vue';
import Show from '@/components/comments/Show.vue';
import authStore from '@/store/auth';
import editStore from '@/store/pitches/edit';
import emitter from '@/vendor/mitt';

export default defineComponent({
    components: {
        CommentPosition,
        CreateForm,
        Show,
    },
    inheritAttrs: false,
    setup() {
        return {
            me: authStore.me,
            showCreateForm: ref<boolean>(false),
            commentCreateForm: ref<typeof CreateForm>(),
            comments: editStore.currentComments,
            currentComment: editStore.currentComment,
            currentCommentUuid: editStore.currentCommentUuid,
            currentScript: editStore.currentScript,
            setCurrentCommentUuid: editStore.setCurrentCommentUuid,
        };
    },
    computed: {
        currentCommentIndex(): number|undefined {
            if (!this.comments) {
                return undefined;
            }

            const index = this.comments
                .search((c: Comment) => c.uuid === this.currentComment?.uuid);

            return index !== false
                ? index
                : undefined;
        },
    },
    watch: {
        currentComment(newComment: Comment | undefined, oldComment: Comment | undefined) {
            if (this.comments?.loading) {
                this.removeOldCommentHighlights();

                return;
            }

            if (
                !newComment
                && oldComment !== undefined
                && oldComment.uuid === this.currentCommentUuid
            ) {
                this.removeOldCommentHighlights();
            } else {
                this.toggleHighlights();
            }
        },
    },
    mounted() {
        emitter.on('addNewCommentHighlight', this.addNewCommentHighlights);

        this.addNewCommentHighlights();
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleDocumentClick);

        emitter.off('addNewCommentHighlight', this.addNewCommentHighlights);

        this.removeOldCommentHighlights();
    },
    methods: {
        goToComment(increment: number) {
            const commentCount = this.comments!.count();

            const nextCommentIndex = ((this.currentCommentIndex || 0) + increment + commentCount) % commentCount;
            const nextCommentUuid = this.comments!.get(nextCommentIndex)?.uuid;

            this.setCurrentCommentUuid(nextCommentUuid || undefined);

            this.toggleHighlights();
        },
        toggleHighlights() {
            this.removeOldCommentHighlights();
            this.addNewCommentHighlights();
        },
        removeOldCommentHighlights() {
            const oldHighlightBlots = document.querySelectorAll('.comment-highlight');

            oldHighlightBlots.forEach((e: Element) => e.classList.remove('active', 'new-comment'));
        },
        addNewCommentHighlights() {
            /*
             * If the current comment is undefined and the comments are not
             * loading, add the `new-comment` class to the highlight and show
             * the create comment form.
             */
            if (
                this.currentCommentUuid
                && !this.currentComment
                && !this.comments!.loading
            ) {
                /*
                 * Add a click listener on the document to hide the create form
                 * if the user clicks anywhere outside it.
                 */
                document.addEventListener('click', this.handleDocumentClick, true);

                this.showCreateComment();

                return;
            }

            const newHighlightBlots = document.querySelectorAll(`.comment-highlight[data-uuid*="${this.currentCommentUuid}"]`);

            newHighlightBlots.forEach((e: Element) => e.classList.add('active'));
        },
        showCreateComment() {
            const newHighlightBlots = document.querySelectorAll(`.comment-highlight[data-uuid*="${this.currentCommentUuid}"]`);

            newHighlightBlots.forEach((e: Element) => e.classList.add('new-comment'));

            this.showCreateForm = true;
        },
        submitCreateForm(comment: Comment) {
            this.setCurrentCommentUuid(comment.uuid);

            this.hideForm();

            this.addNewCommentHighlights();
        },
        cancelCreateForm() {
            emitter.emit('removeCommentHighlight');

            this.setCurrentCommentUuid(undefined);

            this.hideForm();
        },
        hideForm() {
            // Remove the document click handler since the create form will be hidden.
            document.removeEventListener('click', this.handleDocumentClick);

            this.removeOldCommentHighlights();

            this.showCreateForm = false;
        },
        handleDocumentClick(ev: Event) {
            if (
                this.showCreateForm
                && this.commentCreateForm
                && !this.commentCreateForm.$el.contains(ev.target as Node)
            ) {
                this.cancelCreateForm();
            }
        },
    },
});
