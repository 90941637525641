import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card card-bordered" }
const _hoisted_2 = { class: "card-header d-flex justify-content-between align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "d-flex align-items-center text-secondary"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  class: "d-inline-block min-w text-center mx-2",
  "data-test-id": "index-string"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "card-body position-relative overflow-hidden" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "text-end mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_Tip = _resolveComponent("Tip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('tips.title')), 1),
      (_ctx.currentTip && _ctx.tips.count() > 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "btn btn-square btn-xs text-secondary",
              "data-test-id": "btn-prev",
              disabled: _ctx.tipIndex === 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToTip(-1)))
            }, [
              _createVNode(_component_Fa, { icon: "arrow-left" })
            ], 8, _hoisted_4),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('words.xOutOfN', [_ctx.tipIndex + 1, _ctx.tips.count()])), 1),
            _createElementVNode("button", {
              class: "btn btn-square btn-xs text-secondary",
              "data-test-id": "btn-next",
              disabled: _ctx.tipIndex === _ctx.tips.count() - 1,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToTip(1)))
            }, [
              _createVNode(_component_Fa, { icon: "arrow-right" })
            ], 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.currentTip)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_Transition, {
              name: `slide-${_ctx.slideDirection}`
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_component_Tip, {
                  key: _ctx.currentTip.id,
                  tip: _ctx.currentTip
                }, null, 8, ["tip"]))
              ]),
              _: 1
            }, 8, ["name"]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                ref: "copyButton",
                class: "btn btn-circle btn-xs text-secondary",
                "data-test-id": "btn-copy-tip",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.copyTip && _ctx.copyTip(...args)))
              }, [
                _createVNode(_component_Fa, { icon: "copy" })
              ], 512)
            ])
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('tips.noTips')), 1)
          ], 64))
    ])
  ]))
}