
import MarkdownIt, {Options} from 'markdown-it';
import {PropType, defineComponent, ref} from 'vue';

/**
 * Displays the passed source text as markdown.
 * Makes use of the markdown-it package to render the source to HTML.
 */
export default defineComponent({
    name: 'Markdown',
    props: {
        source: {
            type: String,
            required: true,
        },
        options: {
            type: Object as PropType<Options>,
            default: () => ({}),
        },
    },
    setup(props) {
        return {
            md: ref<MarkdownIt>(new MarkdownIt(props.options)),
            wrapper: ref<HTMLDivElement>(),
        };
    },
    computed: {
        markdown(): string {
            return this.md.render(this.source);
        },
    },
    watch: {
        markdown() {
            this.display();
        },
    },
    mounted() {
        this.display();
    },
    methods: {
        display() {
            if (this.wrapper) {
                this.wrapper.innerHTML = this.markdown;
            }
        },
    },
});
