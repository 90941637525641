
import {PropType, defineComponent, ref} from 'vue';
import Resource, {Resources} from '@/models/Resource';
import Media from '@/models/Media';
import ResourceListItem from '@/components/resources/ResourceListItem.vue';
import ResourceType from '@/library/enumerations/ResourceType';
import VideoPlayer from '@/components/common/plyr/VideoPlayer.vue';

export default defineComponent({
    components: {
        ResourceListItem,
        VideoPlayer,
    },
    props: {
        resources: {
            type: Object as PropType<Resources>,
            required: true,
            validate: (r: Resources) => !r.where('type', ResourceType.MEDIA).isEmpty(),
        },
    },
    setup() {
        return {
            currentResource: ref<Resource|undefined>(undefined),
            paused: ref<boolean>(false),
        };
    },
    computed: {
        video(): Media | undefined {
            return this.currentResource
                ?.media
                .first((m: Media) => m.mimeType.startsWith('video'));
        },
    },
    watch: {
        resources() {
            this.currentResource = this.resources.first();
        },
    },
    mounted() {
        this.currentResource = this.resources.first();
    },
});
