import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  ref: "plyrTheatreWrapper",
  class: "plyr-theatre-wrapper position-relative"
}
const _hoisted_2 = {
  ref: "playerElement",
  class: "video-player",
  controls: ""
}
const _hoisted_3 = {
  key: 0,
  class: "inset-center"
}
const _hoisted_4 = {
  id: "plyr-theatre",
  ref: "plyrTheatre",
  class: "position-fixed row align-items-center justify-content-center"
}
const _hoisted_5 = {
  id: "plyr-theatre-video-player",
  ref: "plyrTheatreVideoPlayer",
  class: "col-9"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'plyr-fit-parent': _ctx.fitParent})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("video", _hoisted_2, null, 512),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Spinner)
          ]))
        : _createCommentVNode("", true)
    ], 512),
    (_openBlock(), _createBlock(_Teleport, { to: "#plyr-theatre-wrapper" }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, null, 512)
      ], 512)
    ]))
  ], 2))
}