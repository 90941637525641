import HasLocationMixin, {HasLocation} from '@/models/mixins/HasLocation';
import {Action} from '@/library/model-collection/providers/Provider';
import BaseModel from '@/library/model-collection/Model';
import HasHttpProvider from '@/library/model-collection/providers/HasHttpProvider';
import HttpProvider from '@/library/model-collection/providers/HttpProvider';

export abstract class DefaultModel<A> extends BaseModel<A> implements HasHttpProvider {
    /**
     * @inheritdoc
     */
    abstract endpoint: string;

    /**
     * @inheritdoc
     */
    provider = new HttpProvider();

    /**
     * @inheritdoc
     */
    cancel(message?: string): void {
        this.provider.cancel(message);
    }

    /**
     * @inheritdoc
     */
    getEndpoint(action: Action): string {
        if (['delete', 'fetch', 'update'].includes(action)) {
            return `${this.endpoint}/${this.getIdentifier()}`;
        }

        return this.endpoint;
    }

    /**
     * @inheritdoc
     */
    getFetchParams(): Record<string, any> {
        return {};
    }

    /**
     * @inheritdoc
     */
    getSaveData(): Partial<A> {
        return {};
    }
}

@HasLocationMixin
class Model<A> extends DefaultModel<A> {
    endpoint = '';
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Model<A> extends HasLocation {}

export default Model;
