
import {PropType, defineComponent, ref} from 'vue';
import {closeTheatreMode, openTheatreMode} from '@/components/common/plyr/TheatreMode';
import Hls from 'hls.js';
import Media from '@/models/Media';
import Plyr from 'plyr';
import controls from '@/components/common/plyr/PlyrControls';

/**
 * Shows a video player component.
 * Makes use of the 'plyr' package to turn the video element into a video
 * player.
 * Uses the 'hls.js' package to turn the HLS streams into a video.
 * When the current video is being loaded, a loader will be shown.
 * Optional properties can be set to play the video on mounted or to play the
 * new video when the video source has changed.
 * Emits 'pause' when the video gets paused.
 * Emits 'play' when the video gets played.
 * Emits 'ended' when the video has ended.
 */
export default defineComponent({
    props: {
        fitParent: {
            type: Boolean,
            default: false,
        },
        keepDimensionsInTheatre: {
            type: Boolean,
            default: false,
        },
        offsetFraction: {
            type: Number,
            default: 0,
        },
        playOnMounted: {
            type: Boolean,
            default: false,
        },
        playOnSrcChange: {
            type: Boolean,
            default: false,
        },
        theatreOnMounted: {
            type: Boolean,
            default: false,
        },
        video: {
            type: Object as PropType<Media | null>,
            required: true,
        },
    },
    emits: [
        'pause',
        'play',
        'ended',
        'theatre-mode-closed',
        'theatre-mode-opened',
    ],
    setup() {
        return {
            hls: new Hls(),
            loading: ref<boolean>(false),
            player: ref<Plyr|null>(null),
            playerElement: ref<HTMLVideoElement|null>(null),
            plyrTheatre: ref<HTMLDivElement>(),
            plyrTheatreParent: ref<HTMLElement>(),
            plyrTheatreVideoPlayer: ref<HTMLDivElement>(),
            plyrTheatreWrapper: ref<HTMLDivElement>(),
            theatreMode: ref<boolean>(false),
        };
    },
    watch: {
        video(newVideo, oldVideo) {
            if (!this.player) return;

            if (newVideo) {
                this.hls.loadSource(newVideo.url);
            } else {
                this.player.stop();
            }

            if (this.playOnSrcChange && oldVideo != null) {
                this.player.play();
            }
        },
    },
    mounted() {
        if (this.playerElement && this.video?.url != null) {
            this.hls.loadSource(this.video.url);
            this.hls.attachMedia(this.playerElement);

            this.player = new Plyr(this.playerElement, {
                controls: () => controls,
                tooltips: {
                    controls: false,
                    seek: false,
                },
                autoplay: this.playOnMounted,
            });

            this.player.on('loadstart', this.startLoader);
            this.player.on('loadeddata', this.stopLoader);
            this.player.on('pause', () => this.$emit('pause'));
            this.player.on('play', () => this.$emit('play'));
            this.player.on('ended', () => this.$emit('ended'));

            if (!this.plyrTheatreWrapper) {
                return;
            }

            this.plyrTheatreParent = this.plyrTheatreWrapper!.parentElement || document.body;

            this.addTheatreListeners();

            if (this.theatreOnMounted) {
                this.openTheatreMode();
            }
        }
    },
    beforeUnmount() {
        if (!this.player || !this.hls) return;

        this.player.off('loadstart', this.startLoader);
        this.player.off('loadeddata', this.stopLoader);

        this.hls.destroy();
        this.player.destroy();
    },
    methods: {
        addTheatreListeners() {
            const theatreButton = this.plyrTheatreWrapper!.querySelector('[data-plyr="theatre-mode"]');

            if (theatreButton) {
                theatreButton.addEventListener('click', this.toggleTheatreMode);
            }

            const closeTheatreButton = this.plyrTheatreWrapper!.querySelector('[data-plyr="close-theatre-mode"]');

            if (closeTheatreButton) {
                closeTheatreButton.addEventListener('click', this.closeTheatreMode);
            }
        },
        closeTheatreMode() {
            this.theatreMode = false;

            if (this.keepDimensionsInTheatre) {
                this.plyrTheatreParent!.style.width = '';
                this.plyrTheatreParent!.style.height = '';
            }

            closeTheatreMode(
                this.plyrTheatreWrapper!,
                this.plyrTheatre!,
                this.plyrTheatreParent!,
            );

            this.plyrTheatre!.removeEventListener('click', this.theatreBackdropListener);

            this.$emit('theatre-mode-closed');
        },
        openTheatreMode() {
            this.theatreMode = true;

            if (this.keepDimensionsInTheatre) {
                this.plyrTheatreParent!.style.width = `${this.plyrTheatreParent!.offsetWidth}px`;
                this.plyrTheatreParent!.style.height = `${this.plyrTheatreParent!.offsetHeight}px`;
            }

            openTheatreMode(
                this.plyrTheatreWrapper!,
                this.plyrTheatre!,
                this.plyrTheatreVideoPlayer!,
            );

            this.plyrTheatre!.addEventListener('click', this.theatreBackdropListener);

            this.$emit('theatre-mode-opened');
        },
        theatreBackdropListener(ev: Event) {
            // If the theatre root itself was not clicked, return.
            if (ev.target !== this.plyrTheatre!) {
                return;
            }

            this.closeTheatreMode();
        },
        pause() {
            if (this.player) {
                this.player.pause();
            }
        },
        startLoader() {
            this.loading = true;
        },
        stopLoader() {
            this.loading = false;

            if (this.offsetFraction) {
                this.player!.currentTime = this.player!.duration * this.offsetFraction;
            }
        },
        toggleTheatreMode() {
            if (this.theatreMode) {
                this.closeTheatreMode();
            } else {
                this.openTheatreMode();
            }
        },
    },
});
