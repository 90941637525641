import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "me-2",
  "data-test-id": "icon-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Fa = _resolveComponent("Fa")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn justify-content-center align-items-center", _ctx.classes]),
    disabled: _ctx.isDisabled
  }, [
    (_ctx.showIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.resource.loading)
            ? (_openBlock(), _createBlock(_component_Spinner, {
                key: 0,
                small: "",
                variant: _ctx.spinnerVariant
              }, null, 8, ["variant"]))
            : (_openBlock(), _createBlock(_component_Fa, {
                key: 1,
                icon: _ctx.resource.error ? 'exclamation-triangle' : 'check',
                size: "sm"
              }, null, 8, ["icon"]))
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}