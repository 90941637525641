
import {PropType, defineComponent} from 'vue';
import Base from '@/library/model-collection/Base';

/**
 * Creates a button which displays resource states.
 * Functions like a regular button but can be linked to a resource to show the
 * loading, error, or success state.
 * If none of those states are active, no icon is displayed.
 */
export default defineComponent({
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: true,
        },
        resource: {
            type: Object as PropType<Base<any>>,
            required: true,
        },
        size: {
            type: String,
            default: null,
            validator: (value: string) => ['sm', 'lg'].includes(value),
        },
        spinnerVariant: {
            type: String,
            default: 'white',
        },
        variant: {
            type: String,
            default: 'primary',
        },
    },
    computed: {
        classes(): string[] {
            return [
                `btn-${this.variant}`,
                this.size ? `btn-${this.size}` : '',
                this.inline ? 'd-inline-flex' : 'd-flex',
            ];
        },
        isDisabled(): boolean {
            return this.disabled
                || this.resource.loading;
        },
        showIcon(): boolean {
            return this.resource.loading
                || this.resource.error
                || this.resource.success;
        },
    },
});
