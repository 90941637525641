import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row g-0 user-select-none align-items-center" }
const _hoisted_2 = { class: "col-auto me-4" }
const _hoisted_3 = { class: "col flex-grow-1" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = {
  key: 0,
  class: "text-truncate overflow-hidden whitespace-nowrap fw-bold"
}
const _hoisted_6 = {
  key: 1,
  class: "text-muted whitespace-nowrap ms-2"
}
const _hoisted_7 = {
  class: "btn btn-square btn-xs",
  "data-bs-toggle": "dropdown",
  "data-test-id": "btn-comment-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!
  const _component_Fa = _resolveComponent("Fa")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_OverflowCollapse = _resolveComponent("OverflowCollapse")!
  const _component_UpdateForm = _resolveComponent("UpdateForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.comment.user)
          ? (_openBlock(), _createBlock(_component_ProfilePicture, {
              key: 0,
              class: "square-sm rounded-circle",
              user: _ctx.comment.user
            }, null, 8, ["user"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.comment.user)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.comment.user.displayName), 1))
            : _createCommentVNode("", true),
          (_ctx.comment.updatedAt)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('sentences.timeAgo', [_ctx.commentTimeAgo])), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.isAuthor)
        ? (_openBlock(), _createBlock(_component_Dropdown, {
            key: 0,
            class: "col-auto",
            "data-test-id": "dropdown-comment-actions",
            options: _ctx.commentOptions
          }, {
            "dropdown-toggle": _withCtx(() => [
              _createElementVNode("button", _hoisted_7, [
                _createVNode(_component_Fa, { icon: "ellipsis-v" })
              ])
            ]),
            _: 1
          }, 8, ["options"]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isEditing)
      ? (_openBlock(), _createBlock(_component_OverflowCollapse, {
          key: 0,
          class: "mt-4",
          "data-test-id": "body-comment"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.comment.body), 1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_UpdateForm, {
          key: 1,
          class: "mt-3",
          comment: _ctx.comment,
          onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isEditing = false)),
          onSubmit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isEditing = false))
        }, null, 8, ["comment"]))
  ]))
}