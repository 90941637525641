import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "d-inline-flex align-items-center justify-content-center bg-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.user.profilePicture)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _ctx.src
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("h3", {
          class: _normalizeClass(["text-uppercase text-white fw-normal user-select-none", _ctx.initialClass])
        }, _toDisplayString(_ctx.initial), 3)
      ]))
}