import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import Websocket from '@/library/websockets/Websocket';
import auth from '@/store/auth';
import config from '@/library/data/config';
import qs from 'qs';
import router from '@/router';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use((config: AxiosRequestConfig) => {
    if (!config.headers) {
        config.headers = {};
    }

    if (auth.state.apiToken) {
        // eslint-disable-next-line dot-notation
        config.headers['Authorization'] = `Bearer ${auth.state.apiToken}`;
    }

    if (Websocket.getSocketId()) {
        config.headers['X-Socket-Id'] = Websocket.getSocketId()!;
    }

    config.paramsSerializer = (params: any): string => qs.stringify(params);

    return config;
});

axios.interceptors.response.use((response: AxiosResponse) => response, (error: AxiosError) => {
    if (error.response?.status === 401 && auth.isAuthenticated.value) {
        if (!sessionStorage.getItem(config.REDIRECT_AFTER_AUTH_KEY)) {
            sessionStorage.setItem(config.REDIRECT_AFTER_AUTH_KEY, JSON.stringify(router.currentRoute.value));
        }

        auth.logout();

        router.push({name: 'auth.login'});
    }

    return Promise.reject(error);
});
