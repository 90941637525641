import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Markdown, {
      class: "text-muted mb-6",
      source: _ctx.tip.title || ''
    }, null, 8, ["source"]),
    _createVNode(_component_Markdown, {
      options: {html: true},
      source: _ctx.tip.body || ''
    }, null, 8, ["source"])
  ]))
}