import Resource, {Resources} from '@/models/Resource';
import Cast from '@/library/model-collection/casts/Cast';
import Collection from '@/models/Collection';
import ConstructorCast from '@/library/model-collection/casts/ConstructorCast';
import Model from '@/models/Model';
import ResourceType from '@/library/enumerations/ResourceType';
import addSeconds from 'date-fns/addSeconds';
import format from 'date-fns/format';
import {uniqBy} from 'lodash';

interface SubjectData {
    id?: number;
    name: string;
    maxWords: number;
    resources: Resources;
    placeholder?: string;
}

class Subject extends Model<SubjectData> {
    /**
     * @inheritdoc
     */
    endpoint = 'subjects';

    // Get all the media type resources from the subject.
    get mediaResources(): Resources {
        const resources = this.resources.filter((resource: Resource) => resource.type === ResourceType.MEDIA);

        return new Resources(uniqBy(resources.items, 'id'));
    }

    /**
     * @inheritdoc
     */
    getCasts(): Partial<Record<keyof SubjectData, Cast>> {
        return {
            resources: new ConstructorCast(Resources),
        };
    }

    /**
     * @inheritdoc
     */
    getDefaults(): SubjectData {
        return {
            name: '',
            maxWords: 0,
            resources: new Resources(),
        };
    }
}

interface Subject extends SubjectData {}

export default Subject;

export class Subjects extends Collection<Subject> {
    /**
     * @inheritdoc
     */
    endpoint = 'subjects';

    // Format the total video media duration to a readable string.
    get formattedTotalMediaDuration(): string {
        const date = addSeconds(new Date(0), this.totalMediaDuration);

        return format(date, 'mm:ss');
    }

    // Get the total video media count of all subject resources.
    get totalMediaCount(): number {
        return this.items.reduce((count: number, subject: Subject) => {
            return count + subject.mediaResources.count();
        }, 0);
    }

    // Get the total video media duration of all subject resources.
    get totalMediaDuration(): number {
        return this.items.reduce((durationSum: number, subject: Subject) => {
            return durationSum + subject.mediaResources.totalMediaDuration;
        }, 0);
    }

    /**
     * @inheritdoc
     */
    getModel(): typeof Subject {
        return Subject;
    }
}
