import {RouteRecordRaw} from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/templates/:slug',
        name: 'templates.show',
        component: () => import('@/views/templates/Show.vue'),
        props: true,
    },
    {
        path: '/templates/:slug/script',
        name: 'templates.script',
        component: () => import('@/views/templates/Script.vue'),
        props: true,
    },
];

export default routes;
