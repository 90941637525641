import Comment, {Comments} from '@/models/Comment';
import {Component, ComputedRef, computed, ref, watch} from 'vue';
import Script, {Scripts} from '@/models/Script';
import CommentsList from '@/components/pitches/edit/scripts/Comments.vue';
import Delta from 'quill-delta';
import Op from 'quill-delta/src/Op';
import SharePermission from '@/library/enumerations/SharePermission';
import Tips from '@/components/pitches/edit/scripts/Tips.vue';
import Videos from '@/components/pitches/edit/scripts/Videos.vue';
import {WsPitch} from '@/models/Pitch';
import format from 'date-fns/format';
import get from 'lodash/get';
import {toPlainText} from '@/library/quill/quillDelta';
import uniq from 'lodash/uniq';
import {wordCounter} from '@/library/helpers';

export type ScriptActionKey = 'videos'|'tips'|'comments';

export interface ScriptAction {
    key: ScriptActionKey;
    icon: string;
    iconSelected: string;
    component: Component;
    tooltip: string;
    visible: (value?: any) => boolean;
}

/**
 * A list of all the script actions.
 * By the time this store is imported, the translation messages have not yet
 * been loaded, so we have to set the tooltip to the translation key and translate
 * it in the action nav button component.
 */
const scriptActions: ScriptAction[] = [
    {
        key: 'videos',
        icon: 'play',
        iconSelected: 'play-solid',
        component: Videos,
        tooltip: 'script.resource.videos',
        visible: SharePermission.canEdit,
    },
    {
        key: 'tips',
        icon: 'bolt',
        iconSelected: 'bolt-solid',
        component: Tips,
        tooltip: 'script.resource.tips',
        visible: SharePermission.canEdit,
    },
    {
        key: 'comments',
        icon: 'comment',
        iconSelected: 'comment-filled',
        component: CommentsList,
        tooltip: 'script.resource.comments',
        visible: SharePermission.canComment,
    },
];

/*
 * ----------------------------------------------------------------------------
 * - State --------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

/**
 * The scripts that are active. Users can toggle scripts to change their
 * presence in the script.
 */
const activeScripts = computed<Scripts>(() => pitch.value.scripts.where('active', true));

/**
 * Returns whether the current user has permission to comment on the pitch.
 */
const canComment = computed<boolean>(() => pitch.value.permission !== undefined && SharePermission.canComment(pitch.value.permission));

/**
 * Returns whether the current user has permission to edit the pitch.
 */
const canEdit = computed<boolean>(() => pitch.value.permission !== undefined && SharePermission.canEdit(pitch.value.permission));

/**
 * The uuid of the current comment.
 */
const currentCommentUuid = ref<string|undefined>(undefined);

/**
 * The current comment.
 */
const currentComment = computed<Comment|undefined>(() => {
    return currentComments.value
        ? currentComments.value.firstWhere('uuid', '===', currentCommentUuid.value)
        : undefined;
});

/**
 * The comments of the current script sorted by the highlight position.
 */
const currentComments = computed<Comments|undefined>(() => {
    if (!currentScript.value) {
        return undefined;
    }

    const {body, comments} = currentScript.value;

    // Get all the highlight uuids from the script body.
    const allUuids = body.ops.reduce((acc: string[], op: Op) => {
        const highlights = get(op, 'attributes.highlight');

        if (highlights) {
            acc.push(...highlights.split(','));
        }

        return acc;
    }, []);

    // Filter the uuids to only keep unique entries.
    const uuids = uniq(allUuids);

    const sortedComments = comments.sort((first: Comment, second: Comment) => {
        const firstIndex = uuids.indexOf(first.uuid!);
        const secondIndex = uuids.indexOf(second.uuid!);

        // If indexes are the same, both indexes are -1 so don't change order.
        if (firstIndex === secondIndex) {
            return 0;
        }

        // If the first index is -1, sort the first comment behind the second.
        if (firstIndex < 0) {
            return 1;
        }

        // If the second index is -1, sort the first comment before the second.
        if (secondIndex < 0) {
            return -1;
        }

        return firstIndex - secondIndex;
    });

    comments.items = sortedComments.items;

    return comments;
});

/**
 * The script that is currently opened.
 */
const currentScript = ref<Script|undefined>(undefined);

/**
 * The current action the user is currently looking at. The actions are
 * displayed on the right side of the page. Actions can be videos, tips, or
 * comments.
 */
const currentScriptAction = ref<ScriptAction|undefined>(undefined);

/**
 * A state that indicates if the user is in focus mode. Focus mode hides
 * certain elements of the page so the user can focus more on writing.
 */
const inFocusMode = ref(false);

/**
 * Returns whether the Pitch has any text written in the scripts.
 */
const isEmpty = computed<boolean>(() => {
    return Object.values(wordCounts.value).every((count: number) => count === 0);
});

/**
 * The update delta which is used for validating a new comment.
 */
const newCommentUpdate = ref<Delta|undefined>();

/**
 * The pitch that is being edited.
 */
const pitch = ref(new WsPitch());

/**
 * Indicates if the script nav is visible.
 */
const scriptNavVisible = ref(true);

/**
 * The script actions the user has permission to use.
 */
const visibleScriptActions = computed<ScriptAction[]>(() => scriptActions.filter((s: ScriptAction) => s.visible(pitch.value.permission)));

/**
 * An object of word counts where the keys are the id of a script. It serves a
 * some kind of cache to improve performance.
 */
const wordCounts = ref<Record<number, number>>({});

/*
 * ----------------------------------------------------------------------------
 * - Methods ------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

/**
 * Confirms the specified script and continues to the next.
 */
const confirmScriptAndContinue = (script: Script): void => {
    script.confirmedAt = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

    updatePitch();

    const scriptIndex = getActiveScriptIndex(script);

    if (scriptIndex !== false && scriptIndex + 1 < activeScripts.value.count()) {
        const nextScript = activeScripts.value.get(scriptIndex + 1);

        if (nextScript) {
            currentScript.value = nextScript;
        }
    }
};

/**
 * Fetches the pitch.
 */
const fetch = async (): Promise<void> => {
    try {
        await pitch.value.fetch();
    } catch (e: any) {
        if (!e.response || !e.response.data.errors) throw e;

        return;
    }

    updateWordCounts();
};

/**
 * Fetches the comments of the current script.
 */
const fetchCurrentComments = (): void => {
    if (!currentScript.value || !currentComments.value) {
        return;
    }

    if (currentComments.value.isEmpty()) {
        currentComments.value.endpoint = `/comments/pitch_scripts/${currentScript.value.id}`;

        currentComments.value.fetch().then(() => validateCurrentComment());
    }
};

/**
 * Returns the index of the script in the active scripts collection.
 */
const getActiveScriptIndex = (script: Script): number|false => {
    return activeScripts
        .value
        .search((s: Script) => s.id === script.id);
};

/**
 * Checks if the provided script is current.
 */
const scriptIsCurrent = (script: Script): boolean => {
    return currentScript.value?.id === script.id;
};

/**
 * Sets the current comment uuid.
 */
const setCurrentCommentUuid = (uuid?: string): void => {
    currentCommentUuid.value = uuid;

    if (uuid) {
        setCurrentScriptAction('comments');
    }
};

/**
 * Sets the current script.
 */
const setCurrentScript = (script?: Script): void => {
    currentScript.value = script;
};

/**
 * Sets the current script action.
 */
const setCurrentScriptAction = (key?: ScriptActionKey): void => {
    currentScriptAction.value = visibleScriptActions.value.find((s: ScriptAction) => s.key === key);
};

/**
 * Sets the newCommentUpdate value.
 */
const setNewCommentUpdate = (delta?: Delta): void => {
    newCommentUpdate.value = delta;
};

/**
 * Sets the body of the script on the specified index.
 */
const setScriptBody = (script: Script, body: Delta): void => {
    script.body = body;

    wordCounts.value[script.id as number] = wordCounter(toPlainText(body));
};

/**
 * Sets the slug of the pitch.
 */
const setSlug = (slug: string): void => {
    pitch.value.slug = slug;
};

/**
 * Toggles a script active state.
 */
const toggleScript = (script: Script): void => {
    script.active = !script.active;

    updatePitch();
};

/**
 * Sets the confirmedAt to null for the given script.
 */
const unconfirmScript = (script: Script): void => {
    script.confirmedAt = null;

    updatePitch();
};

/**
 * Updates the pitch.
 */
const updatePitch = (): Promise<void> => {
    return pitch.value.update();
};

/**
 * Update the word count values for all scripts.
 */
const updateWordCounts = (): void => {
    wordCounts.value = {};

    pitch.value.scripts.each((script: Script) => {
        wordCounts.value[script.id as number] = wordCounter(toPlainText(script.body));
    });
};

/**
 * Validate that the current comments contain a comment which has the current
 * comment uuid.
 */
const validateCurrentComment = (): void => {
    if (!currentComments.value) {
        return;
    }

    if (!currentCommentUuid.value) {
        currentCommentUuid.value = currentComments.value.first()?.uuid;
    }

    if (!currentComments.value.firstWhere('uuid', currentCommentUuid.value)) {
        setCurrentCommentUuid(undefined);
    }
};

/*
 * ----------------------------------------------------------------------------
 * - Watchers -----------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

/**
 * Changing current script should fetch the new comments.
 */
watch(currentScript, (newValue?: Record<string, any>) => {
    if (newValue) {
        fetchCurrentComments();

        setCurrentCommentUuid();
    }
});

/**
 * Focus mode should be disabled when user select a script action.
 */
watch(currentScriptAction, (newValue?: ScriptAction) => {
    if (newValue) {
        inFocusMode.value = false;
    }
});

/**
 * Changing focus mode should also alter other things.
 */
watch(inFocusMode, (newValue: boolean) => {
    if (newValue) {
        scriptNavVisible.value = false;

        currentScriptAction.value = undefined;
    } else {
        scriptNavVisible.value = true;

        currentScriptAction.value = currentScriptAction.value || visibleScriptActions.value[0];
    }
});

/**
 * Focus mode should be disabled when user opens script nav.
 */
watch(scriptNavVisible, (newValue: boolean) => {
    if (newValue) {
        inFocusMode.value = false;
    }
});

/*
 * ----------------------------------------------------------------------------
 * - Store --------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

const store = {
    activeScripts,
    canComment,
    canEdit,
    currentCommentUuid,
    currentComment,
    currentComments,
    currentScript,
    currentScriptAction,
    inFocusMode,
    isEmpty,
    newCommentUpdate,
    pitch,
    scriptNavVisible,
    visibleScriptActions,
    wordCounts,

    confirmScriptAndContinue,
    fetch,
    fetchCurrentComments,
    getActiveScriptIndex,
    scriptIsCurrent,
    setCurrentCommentUuid,
    setCurrentScript,
    setCurrentScriptAction,
    setNewCommentUpdate,
    setScriptBody,
    setSlug,
    toggleScript,
    unconfirmScript,
    updatePitch,
    updateWordCounts,
};

export default store;

/*
 * ----------------------------------------------------------------------------
 * - Script store -------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

interface ScriptStore {
    isCurrentScript: ComputedRef<boolean>;
    wordCount: ComputedRef<number>;
}

export function useScriptStore(script: Script): ScriptStore {
    const isCurrentScript = computed(() => scriptIsCurrent(script));

    const wordCount = computed(() => {
        return wordCounts.value[script.id as number] || 0;
    });

    return {
        isCurrentScript,
        wordCount,
    };
}
