import TeamUser, {TeamUserData, TeamUsers} from '@/models/TeamUser';
import Cast from '@/library/model-collection/casts/Cast';
import Collection from '@/models/Collection';
import ConstructorCast from '@/library/model-collection/casts/ConstructorCast';
import {DefaultConversions} from '@/models/Media';
import {InvitationSync} from '@/models/Invitation';
import Model from '@/models/Model';
import TeamType from '@/library/enumerations/TeamType';
import User from '@/models/User';
import get from 'lodash/get';
import pick from 'lodash/pick';

export interface TeamData {
    id?: number;
    name: string;
    slug: string;
    type: TeamType;
    createdAt?: string;
    updatedAt?: string;
    completedAt?: string;
    logo?: DefaultConversions;
    programs?: Teams;
    pitcher?: User;
    usersCount?: number;
    parentId?: number;
    detach?: number[];
    sync?: InvitationSync[];
    currentTeamUser?: TeamUser;
    teamUsers: TeamUsers;
}

class Team extends Model<TeamData> {
    /**
     * @inheritdoc
     */
    endpoint = 'teams';

    get teamBanner(): string|undefined {
        return get(this, 'programs.items.0.banner.original');
    }

    /**
     * @inheritdoc
     */
    getCasts(): Partial<Record<keyof TeamData, Cast>> {
        return {
            programs: new ConstructorCast(Teams),
            pitcher: new ConstructorCast(User),
            currentTeamUser: new ConstructorCast(TeamUser),
            teamUsers: new ConstructorCast(TeamUsers),
        };
    }

    /**
     * @inheritdoc
     */
    getDefaults(): TeamData {
        return {
            name: '',
            slug: '',
            teamUsers: new TeamUsers(),
            type: TeamType.TEAM,
        };
    }

    /**
     * @inheritdoc
     */
    getLocationName(): string {
        return this.type !== undefined
            ? TeamType.getLocationName(this.type)
            : super.getLocationName();
    }

    /**
     * @inheritdoc
     */
    getSaveData(): Partial<Record<string, any>> {
        const data: Record<string, any> = pick(
            this,
            [
                'name',
                'parentId',
                'type',
            ],
        );

        if (this.logo?.original !== this.original.logo?.original) {
            data.logo = this.logo?.original;
        }

        if (
            this.detach?.length
            || this.sync?.length
        ) {
            data.teamUsers = {
                detach: this.detach,
                sync: this.sync,
            };
        }

        return data;
    }

    /**
     * Called when the update request finished successfully.
     */
    onUpdateSuccess(result: any): void {
        if (!result) {
            return;
        }

        const {team, detach, sync} = result;

        super.onUpdateSuccess(team);

        if (detach) {
            detach.forEach((detachId: number) => {
                this.teamUsers.removeFirstWhere((teamUser: TeamUser) => teamUser.getIdentifier() === detachId);
            });
        }

        if (sync) {
            sync.forEach((syncTeamUserData: TeamUserData) => {
                const teamUser = this.teamUsers.firstWhere('id', '===', syncTeamUserData.id);

                if (teamUser) {
                    teamUser.fill(syncTeamUserData);
                } else {
                    const syncTeamUser = new TeamUser(syncTeamUserData, this.teamUsers);

                    this.teamUsers.push(syncTeamUser);
                }
            });
        }
    }
}

interface Team extends TeamData {}

export default Team;

export class Teams extends Collection<Team> {
    /**
     * @inheritdoc
     */
    endpoint = 'teams';

    /**
     * @inheritdoc
     */
    getModel(): typeof Team {
        return Team;
    }
}
