import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "dropdown-toggle", { title: _ctx.title }, () => [
      _createElementVNode("button", {
        class: _normalizeClass(["btn dropdown-toggle min-w-100px text-truncate d-flex justify-content-between align-items-center", _ctx.itemClasses]),
        "data-bs-toggle": "dropdown"
      }, [
        _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
        _createVNode(_component_Fa, {
          class: "ms-2",
          icon: "chevron-down"
        })
      ], 2)
    ]),
    _renderSlot(_ctx.$slots, "dropdown-menu", { itemClasses: _ctx.itemClasses }, () => [
      _createVNode(_component_DropdownMenu, _mergeProps({
        class: [_ctx.menuClass, _ctx.align ? `dropdown-menu-${_ctx.align}` : ''],
        "item-classes": _ctx.itemClasses
      }, _ctx.menuAttrs, { options: _ctx.options }), _createSlots({ _: 2 }, [
        _renderList(_ctx.$slots, (_, slotName) => {
          return {
            name: slotName,
            fn: _withCtx((slotProps) => [
              _renderSlot(_ctx.$slots, slotName, _normalizeProps(_guardReactiveProps(slotProps)))
            ])
          }
        })
      ]), 1040, ["class", "item-classes", "options"])
    ])
  ]))
}