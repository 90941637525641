import {faAddPeople,
    faArrowLeft,
    faArrowRight,
    faBolt,
    faBoltSolid,
    faBook,
    faCalendar,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faClock,
    faComment,
    faCommentFilled,
    faCopy,
    faCrossLarge,
    faCrossSmall,
    faEdit,
    faEllipsisV,
    faEnter,
    faEnterLarge,
    faExclamationTriangle,
    faEye,
    faEyeClosed,
    faFile,
    faGraduationCap,
    faGrid,
    faGripVertical,
    faHamburger,
    faInfo,
    faKey,
    faListActive,
    faNotification,
    faPencil,
    faPencilLarge,
    faPlay,
    faPlaySolid,
    faPlus,
    faPlusLarge,
    faQuoteLeft,
    faRefresh,
    faReload,
    faReloadLarge,
    faSave,
    faSearch,
    faShare,
    faSpeakerMuted,
    faSticker,
    faTrash,
    faUpload,
    faUserFriends,
    faVideo} from '@/library/customIcons';
import {library} from '@fortawesome/fontawesome-svg-core';

export default {
    init(): void {
        library.add(
            faAddPeople,
            faArrowLeft,
            faArrowRight,
            faBoltSolid,
            faBolt,
            faBook,
            faCalendar,
            faCheck,
            faChevronDown,
            faChevronLeft,
            faChevronRight,
            faChevronUp,
            faClock,
            faCommentFilled,
            faComment,
            faCopy,
            faCrossLarge,
            faCrossSmall,
            faEdit,
            faEllipsisV,
            faEnter,
            faEnterLarge,
            faExclamationTriangle,
            faEyeClosed,
            faEye,
            faFile,
            faGraduationCap,
            faGrid,
            faGripVertical,
            faHamburger,
            faInfo,
            faKey,
            faListActive,
            faNotification,
            faPencilLarge,
            faPencil,
            faPlaySolid,
            faPlay,
            faPlusLarge,
            faPlus,
            faQuoteLeft,
            faRefresh,
            faReloadLarge,
            faReload,
            faSave,
            faSearch,
            faShare,
            faSpeakerMuted,
            faSticker,
            faTrash,
            faUpload,
            faUserFriends,
            faVideo,
        );
    },
};
