import Cast from '@/library/model-collection/casts/Cast';
import Collection from '@/models/Collection';
import ConstructorCast from '@/library/model-collection/casts/ConstructorCast';
import Model from '@/models/Model';
import User from '@/models/User';

export interface TeamUserData {
    id?: number;
    modelId?: number;
    userId?: number;
    createdAt?: string;
    updatedAt?: string;
    user?: User;
    roles?: number[];
}

class TeamUser extends Model<TeamUserData> {
    /**
     * @inheritdoc
     */
    endpoint = 'team-users';

    /**
     * @inheritdoc
     */
    getCasts(): Partial<Record<keyof TeamUserData, Cast>> {
        return {
            user: new ConstructorCast(User),
        };
    }
}

interface TeamUser extends TeamUserData {}

export default TeamUser;

export class TeamUsers extends Collection<TeamUser> {
    /**
     * @inheritdoc
     */
    endpoint = 'team-users';

    /**
     * @inheritdoc
     */
    getModel(): typeof TeamUser {
        return TeamUser;
    }
}
