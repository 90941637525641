
import {PropType, defineComponent} from 'vue';

export interface DropdownOption {
    [key: string]: any;
    key: string | number;
    label: string;
    onClick?: () => void;
    variant?: string;
}

export default defineComponent({
    props: {
        emitKey: {
            type: String as PropType<keyof DropdownOption>,
            default: null,
        },
        hideNoResults: {
            type: Boolean,
            default: false,
        },
        itemClasses: {
            type: [String, Array, Object],
            default: null,
        },
        options: {
            type: Array as PropType<DropdownOption[]>,
            required: true,
        },
    },
    emits: [
        'option-selected',
    ],
    methods: {
        selectOption(option: DropdownOption) {
            if (option.onClick !== undefined) {
                option.onClick();
            }

            this.$emit(
                'option-selected',
                this.emitKey
                    ? option[this.emitKey]
                    : option,
            );
        },
    },
});
