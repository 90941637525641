import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref: "overflowCollapseContent",
      class: _normalizeClass(["overflow-collapse-content", {open: _ctx.open}]),
      style: _normalizeStyle(_ctx.collapseCssVariables)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 6),
    (_ctx.showCollapseToggle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "text-primary cursor-pointer mt-2",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
        }, _toDisplayString(_ctx.$t(`words.show${_ctx.open ? 'Less' : 'More'}`)), 1))
      : _createCommentVNode("", true)
  ]))
}