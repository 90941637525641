import {RouteRecordRaw} from 'vue-router';
import dashboardRoutes from '@/router/routes/dashboards';
import pitchMomentRoutes from '@/router/routes/pitchMoments';
import pitchRoutes from '@/router/routes/pitches';
import profileRoutes from '@/router/routes/profiles';
import programRoutes from '@/router/routes/programs';
import resourceRoutes from '@/router/routes/resources';
import teamRoutes from '@/router/routes/teams';
import templateRoutes from '@/router/routes/templates';
import themeBuilderRoutes from '@/router/routes/themeBuilder';
import userRoutes from '@/router/routes/users';

const children: RouteRecordRaw[] = [
    {
        path: '',
        name: 'index',
        children: [
            ...dashboardRoutes,
            ...pitchMomentRoutes,
            ...programRoutes,
            ...userRoutes,
        ],
        component: () => import('@/views/Index.vue'),
        props: true,
        meta: {
            middleware: [
                'Auth',
                'NeedsTeam',
            ],
        },
    },
    {
        path: '',
        children: [
            ...themeBuilderRoutes,
        ],
        component: () => import('@/components/layouts/Empty.vue'),
        meta: {
            middleware: [
                'Auth',
                'NeedsTeam',
            ],
        },
    },
    {
        path: '',
        children: [
            ...resourceRoutes,
            ...templateRoutes,
        ],
        component: () => import('@/components/layouts/Empty.vue'),
    },
    ...pitchRoutes,
    ...profileRoutes,
    ...teamRoutes,
];

const routes: RouteRecordRaw[] = [
    {
        path: '',
        component: () => import('@/components/layouts/Main.vue'),
        children,
        meta: {
            middleware: ['Auth'],
        },
    },
];

export default routes;
