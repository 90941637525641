import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-184000af"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "input-group-prepend"
}
const _hoisted_3 = ["id", "autofocus", "disabled", "placeholder", "readonly", "type", "value"]
const _hoisted_4 = {
  key: 1,
  class: "input-group-append"
}
const _hoisted_5 = {
  key: 1,
  class: "form-text d-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error = _resolveComponent("Error")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "form-label",
          for: _ctx.inputName
        }, _toDisplayString(_ctx.label), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("div", {
        class: _normalizeClass(["input-group", [_ctx.size ? `input-group-${_ctx.size}` : '', {'is-invalid': _ctx.errorRef?.error}]])
      }, [
        (_ctx.hasPrependContent)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "group-prepend", {}, undefined, true)
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "input", {}, () => [
          _createElementVNode("input", {
            id: _ctx.inputName,
            autofocus: _ctx.autofocus,
            class: _normalizeClass(["form-control", _ctx.inputClass]),
            disabled: _ctx.disabled,
            placeholder: _ctx.placeholder,
            readonly: _ctx.readonly,
            type: _ctx.type,
            value: _ctx.modelValue,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
          }, null, 42, _hoisted_3)
        ], true),
        (_ctx.hasAppendContent)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "group-append", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ], true),
    _createVNode(_component_Error, {
      ref: "errorRef",
      class: "d-block",
      "error-key": _ctx.errorKey,
      errors: _ctx.errors
    }, null, 8, ["error-key", "errors"]),
    (_ctx.helpText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "help-text", { helpText: _ctx.helpText }, () => [
            _createTextVNode(_toDisplayString(_ctx.helpText), 1)
          ], true)
        ]))
      : _createCommentVNode("", true)
  ]))
}