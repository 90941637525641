
import {defineComponent} from 'vue';

/**
 * Shows a status error message.
 * By default also shows a link to the status error for more information and a
 * button to go back to the home page.
 */
export default defineComponent({
    props: {
        message: {
            type: String,
            required: true,
        },
        hideStatusLink: {
            type: Boolean,
            default: false,
        },
        iconSize: {
            type: [String, Number],
            default: 1,
        },
        status: {
            type: Number,
            required: true,
        },
    },
});
