const controls = `
<div class="plyr__controls">
    <button
        aria-label="Play, {title}"
        class="plyr__control me-2"
        data-plyr="play"
        type="button"
    >
        <svg
            class="icon--pressed"
            role="presentation"
        >
            <use xlink:href="#plyr-pause" />
        </svg>

        <svg
            class="icon--not-pressed"
            role="presentation"
        >
            <use xlink:href="#plyr-play" />
        </svg>
    </button>

    <button
        class="plyr__control"
        data-plyr="fast-forward"
        type="button"
    >
        <svg
            fill="none"
            height="13"
            viewBox="0 0 11 13"
            width="11"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                color="#000000"
                d="m9.2109 0.93359v11.477h1.6387v-11.477h-1.6387zm-8.1934 0.0078125c-0.40392 0.026866-0.76953 0.35326-0.76953 0.81836v9.0059c0 0.6765 0.77434 1.0617 1.3145 0.6543l5.9688-4.502c0.43481-0.32796 0.43481-0.98063 0-1.3086l-5.9688-4.5039c-0.16878-0.1273-0.36132-0.17627-0.54492-0.16406z"
                fill="#fbfbfb"
            />
        </svg>
    </button>

    <button
        aria-label="Mute"
        class="plyr__control me-4"
        data-plyr="mute"
        type="button"
    >
        <svg
            id="volume-icon"
            fill="none"
            height="16"
            viewBox="0 0 18 16"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.82932 15.7367C9.75618 15.7364 9.68382 15.7216 9.61645 15.6932C9.54908 15.6647 9.48802 15.6231 9.43682 15.5709L5.22442 11.3143H1.53718C1.39057 11.3143 1.24996 11.256 1.14629 11.1524C1.04262 11.0487 0.984375 10.9081 0.984375 10.7615V5.23337C0.984375 5.08676 1.04262 4.94615 1.14629 4.84248C1.24996 4.73881 1.39057 4.68056 1.53718 4.68056H5.22442L9.43682 0.423936C9.5404 0.320975 9.68051 0.263184 9.82655 0.263184C9.9726 0.263184 10.1127 0.320975 10.2163 0.423936C10.3204 0.526002 10.38 0.665089 10.3821 0.810903V15.1839C10.3821 15.3305 10.3239 15.4712 10.2202 15.5748C10.1165 15.6785 9.97593 15.7367 9.82932 15.7367Z"
                fill="#FBFBFB"
            />

            <path
                id="volume-indicator"
                d="m13.3 4.95c0.764 0.7686 1.1933 1.8109 1.1933 2.8977 0 1.0868-0.4293 2.1291-1.1933 2.8977"
                stroke="#FBFBFB"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.6394"
            />

            <path
                id="volume-x"
                d="M17.0158 6.01283L16.2364 5.23337L14.2518 7.21796L12.2672 5.23337L11.4877 6.01283L13.4723 7.99742L11.4877 9.982L12.2672 10.7615L14.2518 8.77688L16.2364 10.7615L17.0158 9.982L15.0312 7.99742L17.0158 6.01283Z"
                fill="#FBFBFB"
            />
        </svg>
    </button>

    <div class="plyr__progress">
        <input
            aria-label="Seek"
            data-plyr="seek"
            max="100"
            min="0"
            step="0.01"
            type="range"
            value="0"
        >

        <progress
            class="plyr__progress__buffer"
            max="100"
            value="0"
        >
            % buffered
        </progress>
    </div>

    <div
        aria-label="Current time"
        class="plyr__time plyr__time--current me-2"
    >
        00:00
    </div>

    <div
        aria-label="Duration"
        class="plyr__time plyr__time--duration"
    >
        00:00
    </div>

    <button
        class="plyr__control ms-auto"
        data-plyr="theatre-mode"
        type="button"
    >
        <svg
            id="open-theatre-icon"
            fill="none"
            height="13"
            viewBox="0 0 13 13"
            width="13"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                color="#000000"
                d="M 0.5 0.5 v 4.5 h 1.5 v -3 h 3 v -1.5 z M 8 0.5 v 1.5 h 3 v 3 h 1.5 v -4.5 z M 0.5 8 v 4.5 h 4.5 v -1.5 h -3 v -3 z M 8 11 v 1.5 h 4.5 v -4.5 h -1.5 v 3 z"
                fill="#fbfbfb"
            />
        </svg>

        <svg
            id="close-theatre-icon"
            fill="none"
            height="13"
            viewBox="0 0 13 13"
            width="13"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                color="#000000"
                d="M 8 8 v 4.5 h 1.5 v -3 h 3 v -1.5 z M 0.5 8 v 1.5 h 3 v 3 h 1.5 v -4.5 z M 8 0.5 v 4.5 h 4.5 v -1.5 h -3 v -3 z M 0.5 3.5 v 1.5 h 4.5 v -4.5 h -1.5 v 3 z"
                fill="#fbfbfb"
            />
        </svg>
    </button>
</div>

<button
    class="plyr__control position-absolute z-index-2 top-2 end-2"
    data-plyr="close-theatre-mode"
    type="button"
>
    <svg
        fill="none"
        height="13"
        viewBox="0 0 13 13"
        width="13"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            color="#000000"
            d="m 1.2 1.2 a 0.5 0.5 0 0 0 0 0.7 l 4.6 4.6 l -4.6 4.6 a 0.5 0.5 0 0 0 0 0.7 a 0.5 0.5 0 0 0 0.7 0 l 4.6 -4.6 l 4.6 4.6 a 0.5 0.5 0 0 0 0.7 0 a 0.5 0.5 0 0 0 0 -0.7 l -4.6 -4.6 l 4.6 -4.6 a 0.5 0.5 0 0 0 0 -0.7 a 0.5 0.5 0 0 0 -0.7 0 l -4.6 4.6 l -4.6 -4.6 a 0.5 0.5 0 0 0 -0.7 0 z"
            fill="#fbfbfb"
        />
    </svg>
</button>
`;

export default controls;
