import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dropdown-menu overflow-y-auto scrollbar" }
const _hoisted_2 = ["data-test-id", "title", "onClick"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_ctx.options.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _renderSlot(_ctx.$slots, "options-prepend"),
          _renderSlot(_ctx.$slots, "options", {}, () => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
              return (_openBlock(), _createElementBlock("li", {
                key: option.key,
                class: _normalizeClass(["dropdown-item cursor-pointer text-truncate", [option.variant ? `text-${option.variant}` : '', _ctx.itemClasses]]),
                "data-test-id": `btn-dropdown-${option.key}`,
                title: option.label,
                onClick: ($event: any) => (_ctx.selectOption(option))
              }, _toDisplayString(option.label), 11, _hoisted_2))
            }), 128))
          ]),
          _renderSlot(_ctx.$slots, "options-append")
        ], 64))
      : (!_ctx.hideNoResults)
        ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
            _renderSlot(_ctx.$slots, "dropdown-no-results", {}, () => [
              _createElementVNode("button", {
                class: _normalizeClass(["btn dropdown-item text-truncate pointer-events-none", _ctx.itemClasses]),
                "data-test-id": "no-results",
                type: "button"
              }, _toDisplayString(_ctx.$t('words.noResults')), 3)
            ])
          ]))
        : _createCommentVNode("", true)
  ]))
}