import {RouteRecordRaw} from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: 'programs',
        name: 'programs.index',
        component: () => import('@/views/programs/Index.vue'),
        meta: {
            navbarBorder: true,
        },
    },
];

export default routes;
