
import {PropType, defineComponent} from 'vue';
import User, {UserData} from '@/models/User';

export default defineComponent({
    props: {
        initialClass: {
            type: String,
            default: 'fs-6',
        },
        user: {
            type: Object as PropType<User>,
            required: true,
        },
        srcKey: {
            type: String,
            /*
             * Don't just change this value. It could have great negative impact
             * on performance.
             */
            default: 'thumbXs',
        },
    },
    computed: {
        initial(): string {
            const name = this.user.displayName
                || this.$t('users.guest');

            return name[0];
        },
        src(): string|undefined {
            const key = this.srcKey as keyof UserData['profilePicture'];

            return this.user.profilePicture![key];
        },
    },
});
