/*
 * Different Confetti configurations.
 * For more info, see https://www.kirilv.com/canvas-confetti/
 */
import confetti, {Options} from 'canvas-confetti';

interface ConfettiRatio extends Options {
    ratio: number;
}

const colors = [
    '#f20000',
    '#ff6700',
    '#fff800',
    '#5aff00',
    '#0092ff',
    '#7700ff',
];

const cannon = (x: number = 0.5, y: number = 0.95): void => {
    const defaults = {
        colors,
        disableForReducedMotion: true,
        origin: {
            x,
            y,
        },
        ticks: 150,
    };

    const totalParticles = 100;

    const ratios: ConfettiRatio[] = [
        {
            ratio: 0.25,
            spread: 25,
            startVelocity: 55,
        },
        {
            ratio: 0.2,
            spread: 60,
        },
        {
            ratio: 0.35,
            spread: 100,
            decay: 0.91,
            scalar: 0.8,
        },
        {
            ratio: 0.1,
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
        },
        {
            ratio: 0.1,
            spread: 120,
        },
    ];

    ratios.forEach((confettiRatio: ConfettiRatio) => {
        confetti({
            ...defaults,
            ...confettiRatio,
            particleCount: Math.floor(totalParticles * confettiRatio.ratio),
        });
    });
};

const sideFountains = (duration: number = 1500, y: number = 0.5): void => {
    const endTime = Date.now() + duration;

    let iteration = 0;

    const sideCannonsBlast = (): void => {
        confetti({
            disableForReducedMotion: true,
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: {
                x: 0,
                y,
            },
            colors: [colors[iteration]],
            ticks: 75,
        });

        confetti({
            disableForReducedMotion: true,
            particleCount: 2,
            angle: 120,
            spread: 55,
            origin: {
                x: 1,
                y,
            },
            colors: [colors[iteration]],
            ticks: 75,
        });

        if (Date.now() < endTime) {
            iteration = (iteration + 1) % colors.length;

            requestAnimationFrame(sideCannonsBlast);
        }
    };

    sideCannonsBlast();
};

const stagedCannons = (duration: number = 250, stages: number = 3): void => {
    const cannonStage = (yPos: number = 0.5): void => {
        confetti({
            disableForReducedMotion: true,
            particleCount: 50,
            angle: 60,
            spread: 45,
            origin: {
                x: 0,
                y: yPos,
            },
            colors,
            ticks: 75,
        });

        confetti({
            disableForReducedMotion: true,
            particleCount: 50,
            angle: 120,
            spread: 45,
            origin: {
                x: 1,
                y: yPos,
            },
            colors,
            ticks: 75,
        });
    };

    for (let stage = 0; stage < stages; stage++) {
        const yPos = 1 - (1 / stages) * stage;

        setTimeout(() => {
            cannonStage(yPos);
        }, (duration / (stages - 1)) * stage);
    }
};

export {
    cannon,
    sideFountains,
    stagedCannons,
};
