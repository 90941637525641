import {RouteRecordRaw} from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: 'pitch-moments',
        name: 'pitchMoments.index',
        component: () => import('@/views/pitchMoments/Index.vue'),
        meta: {
            navbarBorder: true,
        },
    },
    {
        path: 'pitch-moments/:id',
        name: 'pitchMoments.show',
        component: () => import('@/views/pitchMoments/Show.vue'),
        meta: {
            navbarBorder: true,
        },
        props: true,
    },
    {
        path: 'pitch-moments/:id/edit',
        name: 'pitchMoments.edit',
        component: () => import('@/views/pitchMoments/Edit.vue'),
        meta: {
            navbarBorder: true,
        },
        props: true,
    },
];

export default routes;
