import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2580771f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_BtnResource = _resolveComponent("BtnResource")!

  return (_openBlock(), _createElementBlock("form", {
    class: "form-wrapper",
    onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
  }, [
    _createVNode(_component_FormGroup, {
      "error-key": "body",
      errors: _ctx.comment.errors
    }, {
      input: _withCtx(() => [
        _withDirectives(_createElementVNode("textarea", {
          ref: "fieldBody",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.comment.body) = $event)),
          class: _normalizeClass(["form-control", _ctx.inputClass]),
          "data-test-id": "input-comment",
          placeholder: _ctx.$t('comments.placeholder'),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur'))),
          onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focus')))
        }, null, 42, _hoisted_1), [
          [_vModelText, _ctx.comment.body]
        ])
      ]),
      _: 1
    }, 8, ["errors"]),
    _createElementVNode("div", {
      class: _normalizeClass(["text-end form-buttons mt-2", {'force-show': _ctx.forceShowButtons}])
    }, [
      _createElementVNode("button", {
        class: "btn btn-sm btn-outline-light",
        type: "button",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
      }, _toDisplayString(_ctx.$t('actions.cancel')), 1),
      _createVNode(_component_BtnResource, {
        class: "ms-2",
        "data-test-id": "btn-comment",
        resource: _ctx.comment,
        size: "sm",
        type: "submit"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('actions.send')), 1)
        ]),
        _: 1
      }, 8, ["resource"])
    ], 2)
  ], 32))
}