/* eslint-disable import/prefer-default-export */
// Check whether the element's bottom scrolling threshold has been reached or not.
import Collection from '@/models/Collection';
import {nextTick} from 'vue';

const elementThresholdReached = (el: Element, offset: number = 50): boolean => {
    if (!el) {
        return false;
    }

    const bottomPosition = el.scrollTop + el.clientHeight;

    return bottomPosition >= el.scrollHeight - offset;
};

// Keep fetching the collection until the scrolling element can scroll
const fetchCollectionUntilScrollable = async (
    collection: Collection<Record<string, any>, Record<string, any>>,
    scrollingElement: Element,
    options: Record<string, any> = {},
): Promise<void> => {
    let continueFetching = false;

    do {
        await nextTick();

        const result = await collection.fetch(options);

        continueFetching = result != null;
    } while (
        continueFetching
        && !collection.lastPageFetched()
        && elementThresholdReached(scrollingElement)
    );
};

/**
 * Finds the first parent element that has its overflow-y property set to scroll or auto.
 * If no (suitable) parent is found, it return the document's scrolling element.
 */
const firstScrollingParent = (el: Element): Element => {
    let parent = el.parentElement;

    while (
        parent != null
        && !['auto', 'scroll'].includes(getComputedStyle(parent).overflowY)
    ) {
        parent = parent.parentElement;
    }

    return (parent === document.body || parent == null)
        ? document.scrollingElement!
        : parent;
};

export {
    elementThresholdReached,
    fetchCollectionUntilScrollable,
    firstScrollingParent,
};
