import {RouteRecordRaw} from 'vue-router';

const children: RouteRecordRaw[] = [
    {
        path: '/profile/create',
        name: 'profile.create',
        component: () => import('@/views/profile/Create.vue'),
        props: true,
    },
];

const routes: RouteRecordRaw[] = [
    {
        path: '',
        component: () => import('@/components/layouts/auth/Auth.vue'),
        children,
    },
];

export default routes;
