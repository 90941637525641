const closeTheatreMode = (plyrElement: HTMLElement, theatreRoot: HTMLElement, parentElement: HTMLElement): void => {
    parentElement.appendChild(plyrElement);

    if (!theatreRoot) {
        return;
    }

    theatreRoot.classList.remove('show');

    document.body.style.removeProperty('overflow');
};

const openTheatreMode = (plyrElement: HTMLElement, theatreRoot: HTMLElement, theatreVideoPlayer: HTMLElement): void => {
    if (!theatreRoot || !theatreVideoPlayer) {
        return;
    }

    theatreVideoPlayer.appendChild(plyrElement);

    theatreRoot.classList.add('show');

    document.body.style.setProperty('overflow', 'hidden');
};

export {
    closeTheatreMode,
    openTheatreMode,
};
