import {RouteRecordRaw} from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/users/edit',
        name: 'users.edit',
        component: () => import('@/views/users/Edit.vue'),
    },
];

export default routes;
