import Enumeration, {Enum} from '@/library/enumerations/Enumeration';

@Enum
export default class TeamType extends Enumeration {
    static ORGANISATION = 0;
    static PROGRAM = 1;
    static TEAM = 2;

    static getLocationName(type: TeamType): string {
        switch (type) {
            case TeamType.ORGANISATION:
                return 'organisations';
            case TeamType.PROGRAM:
                return 'programs';
            default:
                return 'teams';
        }
    }
}
