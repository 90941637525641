import mitt from 'mitt';

type Events = {
    addNewCommentHighlight?: null;
    highlightBlotDeleted: string;
    removeCommentHighlight?: null;
}

const emitter = mitt<Events>();

export default emitter;
