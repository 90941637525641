import {RouteRecordRaw} from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/resources/:id',
        name: 'resources.show',
        component: () => import('@/views/resources/Show.vue'),
        props: true,
    },
];

export default routes;
