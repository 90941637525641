import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c6f2bb1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-secondary small"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["resource-list-item rounded-3", {border: _ctx.border}])
  }, [
    _createElementVNode("h6", {
      class: _normalizeClass({'mb-2': _ctx.mediaPropertyExists})
    }, _toDisplayString(_ctx.index !== null ? `${_ctx.index + 1}.` : '') + " " + _toDisplayString(_ctx.resource.title), 3),
    (_ctx.mediaPropertyExists)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true)
  ], 2))
}