import Delta from 'quill-delta';
import Op from 'quill-delta/src/Op';
import {QuillDeltaToHtmlConverter} from 'quill-delta-to-html';

const toHTML = (delta: Delta): string => {
    const converter = new QuillDeltaToHtmlConverter(delta.ops);

    return converter.convert();
};

const toPlainText = (delta: Delta): string => delta
    .filter((op: Op) => typeof op.insert === 'string')
    .map((op: Op) => (op.insert as string).replace(/\n/g, ' '))
    .join('')
    .trim();

export {
    toHTML,
    toPlainText,
};
