import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mt-6" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Fa, {
      class: "text-danger",
      icon: "exclamation-triangle",
      size: `${_ctx.iconSize}x`
    }, null, 8, ["size"]),
    _createElementVNode("p", _hoisted_1, [
      (!_ctx.hideStatusLink)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "text-decoration-none pe-2 me-1 border-end border-dark",
            href: `https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/${_ctx.status}`,
            target: "_blank"
          }, _toDisplayString(_ctx.status), 9, _hoisted_2))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.message), 1)
    ]),
    (!_ctx.hideStatusLink)
      ? (_openBlock(), _createBlock(_component_RouterLink, {
          key: 0,
          class: "text-decoration-none",
          to: {name: 'home'}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('actions.backToHome')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}