import HasWebsocketMixin, {HasWebsocket} from '@/models/mixins/HasWebsocket';
import Script, {Scripts, WsScripts} from '@/models/Script';
import Tag, {Tags} from '@/models/tags/Tag';
import Cast from '@/library/model-collection/casts/Cast';
import Collection from '@/models/Collection';
import ConstructorCast from '@/library/model-collection/casts/ConstructorCast';
import Model from '@/models/Model';
import PitchMoment from '@/models/PitchMoment';
import {PusherPresenceChannel} from 'laravel-echo/src/channel/pusher-presence-channel';
import Team from '@/models/Team';
import Template from '@/models/Template';

export interface PitchData {
    id?: number;
    name: string;
    slug?: string;
    teamId?: number;
    team?: Team;
    templateId?: number;
    pitchMomentId?: number;
    pitchMoment?: PitchMoment;
    reviewableSince?: string;
    scripts: Scripts;
    template?: Template;
    tags: Tags;
    createdAt?: string;
    updatedAt?: string;
    permission?: number;
    isSharedWithUser?: boolean;
}

class Pitch extends Model<PitchData> {
    /**
     * @inheritdoc
     */
    endpoint = 'pitches';

    /**
     * @inheritdoc
     */
    primaryKey: keyof PitchData = 'slug';

    /**
     * @inheritdoc
     */
    getCasts(): Partial<Record<keyof PitchData, Cast>> {
        return {
            team: new ConstructorCast(Team),
            pitchMoment: new ConstructorCast(PitchMoment),
            scripts: new ConstructorCast(Scripts),
            template: new ConstructorCast(Template),
            tags: new ConstructorCast(Tags),
        };
    }

    /**
     * @inheritdoc
     */
    getDefaults(): PitchData {
        return {
            name: '',
            slug: undefined,
            teamId: undefined,
            templateId: undefined,
            scripts: new Scripts(),
            tags: new Tags(),
            template: new Template(),
        };
    }

    /**
     * @inheritdoc
     */
    getSaveData(): Partial<Record<string, any>> {
        const tagIds = this.tags.all().map((tag: Tag) => tag.id);
        const scripts = this.scripts.all().map((script: Script) => script.getSaveData());

        return {
            name: this.name,
            teamId: this.teamId,
            templateId: this.template?.id,
            tags: tagIds,
            pitchMomentId: this.pitchMoment?.id || null,
            scripts,
            reviewableSince: this.reviewableSince,
        };
    }
}

interface Pitch extends PitchData {}

export default Pitch;

export class Pitches extends Collection<Pitch> {
    /**
     * @inheritdoc
     */
    endpoint = 'pitches';

    /**
     * @inheritdoc
     */
    getModel(): typeof Pitch {
        return Pitch;
    }
}

@HasWebsocketMixin
class WsPitch extends Pitch {
    /**
     * @inheritdoc
     */
    getCasts(): Partial<Record<keyof PitchData, Cast>> {
        const casts = super.getCasts();

        casts.scripts = new ConstructorCast(WsScripts);

        return casts;
    }

    /**
     * @inheritdoc
     */
    getChannelName(): string | undefined {
        return this.getIdentifier()
            ? `Pitch.${this.getIdentifier()}`
            : undefined;
    }

    /**
     * @inheritdoc
     */
    getDefaults(): PitchData {
        const defaults = super.getDefaults();

        defaults.scripts = new WsScripts() as Scripts;

        return defaults;
    }

    /**
     * @inheritdoc
     */
    registerListeners(channel: PusherPresenceChannel): void {
        channel.listen('Pitches\\PitchUpdate', (data: Record<string, any>) => {
            this.fill(data);

            this.scripts = this.scripts.sortBy('index');
        });
    }
}

interface WsPitch extends HasWebsocket {}

export {WsPitch};
