import Cast from '@/library/model-collection/casts/Cast';
import Collection from '@/models/Collection';
import Comment from '@/models/Comment';
import ConstructorCast from '@/library/model-collection/casts/ConstructorCast';
import Invitation from '@/models/Invitation';
import Model from '@/models/Model';
import NotificationType from '@/library/enumerations/NotificationType';
import Pitch from '@/models/Pitch';
import {RouteLocationRaw} from 'vue-router';
import User from '@/models/User';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import get from 'lodash/get';

interface NotificationData {
    id?: string;
    type?: NotificationType;
    notifiableType?: string;
    notifiableId?: string|number;
    initiatorId?: number;
    modelType?: string;
    modelId?: string|number;
    data: any;
    readAt?: string;
    createdAt?: string;
    updatedAt?: string;
    notifiable?: User;
    initiator?: User;
    model?: Comment|Invitation;
}

class Notification extends Model<NotificationData> {
    /**
     * @inheritdoc
     */
    endpoint = 'notifications';

    /**
     * The subject of a notification.
     */
    get subject(): string|undefined {
        switch (this.type) {
            case NotificationType.COMMENT:
                return get(this, 'model.commentable.pitch.name');
            case NotificationType.REPLY:
                return get(this, 'model.commentable.commentable.pitch.name');
            case NotificationType.INVITATION:
                return get(this, 'model.model.name');
            default:
                return '';
        }
    }

    get createdTimeAgo(): string|undefined {
        return this.createdAt
            ? formatDistanceToNowStrict(new Date(this.createdAt))
            : undefined;
    }

    /**
     * @inheritdoc
     */
    getCasts(): Partial<Record<keyof NotificationData, Cast>> {
        const model = this.type && this.type === NotificationType.INVITATION
            ? Invitation
            : Comment;

        return {
            notifiable: new ConstructorCast(User),
            initiator: new ConstructorCast(User),
            model: new ConstructorCast(model),
        };
    }

    /**
     * @inheritdoc
     */
    getDefaults(): NotificationData {
        return {
            data: {},
        };
    }

    /**
     * The route to the model of the notification.
     */
    getModelLocation(): RouteLocationRaw {
        let pitch: Pitch | undefined;

        switch (this.type) {
            case NotificationType.COMMENT:
                pitch = get(this, 'model.commentable.pitch');
                break;
            case NotificationType.REPLY:
                pitch = get(this, 'model.commentable.commentable.pitch');
                break;
            case NotificationType.INVITATION:
                pitch = get(this, 'model.model');
                break;
            default:
                break;
        }

        return pitch
            ? pitch.getLocation()
            : {name: 'home'};
    }
}

interface Notification extends NotificationData {}

export default Notification;

export class Notifications extends Collection<Notification> {
    /**
     * @inheritdoc
     */
    endpoint = 'notifications';

    /**
     * Returns the unread notifications.
     */
    get unread(): Notifications {
        return this.where('readAt', '===', null);
    }

    /**
     * @inheritdoc
     */
    getModel(): typeof Notification {
        return Notification;
    }
}
