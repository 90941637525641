import {RouteRecordRaw} from 'vue-router';
import TeamType from '@/library/enumerations/TeamType';

const children: RouteRecordRaw[] = [
    {
        /*
         * This path is part of the parent path '/auth/register',
         * which implies that this is not the default organisations route.
         * For this reason '/create' is not needed after the path.
         */
        path: 'organisations/:id',
        name: 'organisations.register',
        component: () => import('@/views/teams/Create.vue'),
        props: {
            teamType: TeamType.ORGANISATION,
        },
    },
    {
        path: 'organisations/:id/invitations',
        name: 'organisations.register.invitations',
        component: () => import('@/views/teams/Invitations.vue'),
        props: {
            teamType: TeamType.ORGANISATION,
        },
    },
    {
        /*
         * This path is part of the parent path '/auth/register',
         * which implies that this is not the default teams route.
         * For this reason '/create' is not needed after the path.
         */
        path: 'teams/:id',
        name: 'teams.register',
        component: () => import('@/views/teams/Create.vue'),
        props: {
            teamType: TeamType.TEAM,
        },
    },
    {
        path: 'teams/:id/invitations',
        name: 'teams.register.invitations',
        component: () => import('@/views/teams/Invitations.vue'),
        props: {
            teamType: TeamType.TEAM,
        },
    },
    {
        path: 'teams/:id/assign-pitcher',
        name: 'teams.register.assignPitcher',
        component: () => import('@/views/teams/AssignPitcher.vue'),
    },
];

const routes: RouteRecordRaw[] = [
    {
        path: '/auth/register',
        component: () => import('@/components/layouts/teams/Register.vue'),
        props: true,
        children,
    },
];

export default routes;
