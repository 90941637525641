import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row align-items-center g-0 user-select-none mb-4 text-muted" }
const _hoisted_2 = { class: "col-auto me-4 whitespace-nowrap" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "card card-bordered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_Show = _resolveComponent("Show")!
  const _component_CommentPosition = _resolveComponent("CommentPosition")!
  const _component_CreateForm = _resolveComponent("CreateForm")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_WaitForResource, { resource: _ctx.comments }, {
      default: _withCtx(() => [
        (_ctx.currentComment && !_ctx.showCreateForm)
          ? (_openBlock(), _createBlock(_component_CommentPosition, {
              key: 0,
              class: "transition",
              "comment-uuid": _ctx.currentComment.uuid
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Show, null, {
                  "comment-header": _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_Fa, {
                          class: "cursor-pointer",
                          icon: "chevron-up",
                          size: "sm",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToComment(-1)))
                        }),
                        _createVNode(_component_Fa, {
                          class: "cursor-pointer ms-2",
                          "data-test-id": "btn-next",
                          icon: "chevron-down",
                          size: "sm",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToComment(1)))
                        })
                      ]),
                      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('words.xOutOfN', [_ctx.currentCommentIndex + 1, _ctx.comments.count()])), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["comment-uuid"]))
          : (_ctx.showCreateForm)
            ? (_openBlock(), _createBlock(_component_CommentPosition, {
                key: 1,
                "comment-uuid": _ctx.currentCommentUuid
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_CreateForm, {
                      ref: "commentCreateForm",
                      class: "card-body",
                      commentable: _ctx.currentScript,
                      "focus-on-mount": "",
                      "force-show-buttons": "",
                      uuid: _ctx.currentCommentUuid,
                      onCancel: _ctx.cancelCreateForm,
                      onSubmit: _ctx.submitCreateForm
                    }, null, 8, ["commentable", "uuid", "onCancel", "onSubmit"])
                  ])
                ]),
                _: 1
              }, 8, ["comment-uuid"]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["resource"])
  ]))
}