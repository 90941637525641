import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card card-bordered",
  "data-test-id": "card-comment"
}
const _hoisted_2 = { class: "card-body pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Body = _resolveComponent("Body")!
  const _component_CreateForm = _resolveComponent("CreateForm")!

  return (_ctx.comment)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "comment-header"),
          _createVNode(_component_Body, {
            comment: _ctx.comment,
            "data-test-id": "comment-body",
            onCommentRemoved: _ctx.removeCommentHighlight
          }, null, 8, ["comment", "onCommentRemoved"])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comment.comments, (reply) => {
          return (_openBlock(), _createBlock(_component_Body, {
            key: `reply-${reply.id}`,
            class: "card-body border-top",
            comment: reply,
            "data-test-class": "comment-reply-body"
          }, null, 8, ["comment"]))
        }), 128)),
        _createVNode(_component_CreateForm, {
          class: _normalizeClass(["card-footer pt-4 transition", {'bg-light': _ctx.replyFocused}]),
          commentable: _ctx.comment,
          onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.replyFocused = false)),
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.replyFocused = true))
        }, null, 8, ["class", "commentable"])
      ]))
    : _createCommentVNode("", true)
}