import {elementThresholdReached, firstScrollingParent} from '@/library/helpers';
import {DirectiveBinding} from 'vue';
import debounce from 'lodash/debounce';

/*
 * A WeakMap that is used to store the element, target and scroll handler.
 * This is needed so the handler can be removed when the directive is unmounted.
 */
const state = new WeakMap();

/*
 * Get the target element on which to put the scroll listener. It is based on the
 * modifier and can be either the first scrolling parent, document or itself.
 */
const getTargetElement = (el: HTMLElement, binding: DirectiveBinding): Element => {
    if (binding.modifiers.parent) {
        return firstScrollingParent(el);
    }

    if (binding.modifiers.document) {
        return document.scrollingElement!;
    }

    return el;
};

/*
 * Creates a function to trigger on scroll events. The target threshold will be
 * checked and if deemed truthy, the callback will be called. In case of any error,
 * the scroll listener will be removed.
 */
const createScrollHandler = (el: HTMLElement, target: Element, callback: () => void): () => Promise<any> => async function thresholdCheck() {
    if (elementThresholdReached(target)) {
        try {
            await callback();
        } catch (e: any) {
            removeScrollListener(el);
        }
    }
};

const addScrollListener = (el: HTMLElement, binding: DirectiveBinding, callback: () => void): void => {
    if (typeof callback !== 'function') {
        throw new Error('Infinite scroll directive requires a function.');
    }

    const {disabled} = binding.value;

    let target: Element | Window = getTargetElement(el, binding);
    const scrollHandler = debounce(
        createScrollHandler(el, target, callback),
        500,
    );

    /*
     * The document.scrollingElement does not fire any scroll events, so we need
     * to set the target to the window in that case.
     */
    target = target === document.scrollingElement
        ? window
        : target;

    if (!disabled) {
        target.addEventListener('scroll', scrollHandler);
    }

    /*
     * Store the target, scroll handler and disabled state in the state, with
     * the element as the key.
     */
    state.set(
        el,
        {
            target,
            scrollHandler,
            disabled: disabled || false,
        },
    );
};

const removeScrollListener = (el: HTMLElement): void => {
    // Get the target and scroll handler from the state.
    const {target, scrollHandler} = state.get(el);

    if (target && typeof scrollHandler === 'function') {
        target.removeEventListener('scroll', scrollHandler);

        state.delete(el);
    }
};

const updateScrollListener = (el: HTMLElement, binding: DirectiveBinding): void => {
    const bindingDisabled = binding.value.disabled || false;

    // Get the target, scroll handler and disabled state from the state.
    const {target, scrollHandler, disabled} = state.get(el);

    // If the disabled state didn't change, don't update event listener.
    if (bindingDisabled === disabled) {
        return;
    }

    if (bindingDisabled) {
        target.removeEventListener('scroll', scrollHandler);
    } else {
        target.addEventListener('scroll', scrollHandler);
    }

    // Update the state entry.
    state.set(
        el,
        {
            target,
            scrollHandler,
            disabled: bindingDisabled,
        },
    );
};

export default {
    mounted(el: HTMLElement, binding: DirectiveBinding): void {
        const {callback} = binding.value;

        addScrollListener(el, binding, callback);
    },
    updated(el: HTMLElement, binding: DirectiveBinding): void {
        updateScrollListener(el, binding);
    },
    beforeUnmount(el: HTMLElement): void {
        removeScrollListener(el);
    },
};

export {
    addScrollListener,
    removeScrollListener,
    updateScrollListener,
};
