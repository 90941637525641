
import {defineComponent} from 'vue';

/**
 * Shows a Bootstrap spinner component.
 * The size and variant can be altered by use of the props.
 */
export default defineComponent({
    props: {
        small: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'primary',
        },
    },
    computed: {
        classes(): string[] {
            return [
                this.variant ? `text-${this.variant}` : '',
                this.small ? 'spinner-border-sm' : '',
            ];
        },
    },
});
