import {UserData, WsUser} from '@/models/User';
import {computed, reactive, readonly} from 'vue';
import axios from 'axios';

const API_TOKEN_STORAGE_KEY = 'apiToken';

const state = reactive({
    apiToken: localStorage.getItem(API_TOKEN_STORAGE_KEY) as string | null,
    me: null as WsUser | null,
});

const setApiToken = (token: string | null): void => {
    if (token) {
        state.apiToken = token;

        localStorage.setItem(API_TOKEN_STORAGE_KEY, token);
    } else {
        state.apiToken = null;

        localStorage.removeItem(API_TOKEN_STORAGE_KEY);
    }
};

const setMe = (user: WsUser | UserData | null): void => {
    if (user) {
        state.me = user instanceof WsUser
            ? user
            : new WsUser(user);
    } else {
        state.me = null;
    }
};

const apiToken = computed(() => {
    const localToken = localStorage.getItem(API_TOKEN_STORAGE_KEY);

    if (!state.apiToken && localToken) {
        setApiToken(localToken);
    }

    return state.apiToken;
});

const me = computed(() => state.me);

const isAuthenticated = computed(() => apiToken.value !== null);

const login = async (email: string, password: string): Promise<any> => {
    try {
        const {data} = await axios.post('/auth/login', {
            email,
            password,
        });

        setApiToken(data.apiToken);
        setMe(data.user);

        return {data};
    } catch (e: any) {
        setApiToken(null);

        throw e;
    }
};

const logout = (): void => {
    setMe(null);
    setApiToken(null);
};

export default {
    state: readonly(state),
    login,
    logout,
    setApiToken,
    setMe,
    isAuthenticated,
    me,
};
