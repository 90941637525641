import {RouteRecordRaw} from 'vue-router';

const children: RouteRecordRaw[] = [
    {
        path: '/auth/login',
        name: 'auth.login',
        component: () => import('@/views/auth/Login.vue'),
    },
    {
        path: '/auth/forgot-password',
        name: 'auth.forgotPassword',
        component: () => import('@/views/auth/ForgotPassword.vue'),
    },
    {
        path: '/auth/reset-password',
        name: 'auth.resetPassword',
        component: () => import('@/views/auth/ResetPassword.vue'),
    },
];

const routes: RouteRecordRaw[] = [
    {
        path: '',
        component: () => import('@/components/layouts/auth/Auth.vue'),
        children,
        meta: {
            middleware: ['Guest'],
        },
    },
];

export default routes;
