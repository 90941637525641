import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_StatusError = _resolveComponent("StatusError")!

  return (_ctx.isLoading || _ctx.hasError)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["d-flex justify-content-center align-items-center", _ctx.classes])
      }, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
          : (_ctx.resource.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_StatusError, {
                  "hide-status-link": !_ctx.showErrorDetails,
                  message: _ctx.resource.errorMessage,
                  status: _ctx.resource.errorStatus
                }, null, 8, ["hide-status-link", "message", "status"])
              ]))
            : _createCommentVNode("", true)
      ], 2))
    : _renderSlot(_ctx.$slots, "default", { key: 1 })
}