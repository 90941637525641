import {RouteRecordRaw} from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '',
        name: 'home',
        component: () => import('@/views/Dashboard.vue'),
        meta: {
            navbarBorder: true,
        },
    },
    {
        path: 'academy',
        name: 'academy',
        component: () => import('@/views/academy/Index.vue'),
        meta: {
            stickyNavbar: true,
        },
    },
];

export default routes;
