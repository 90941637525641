/* eslint-disable import/prefer-default-export */
import {useFavicon, useTitle} from '@vueuse/core';
import config from '@/library/data/config';
import get from 'lodash/get';

const themes = [
    'default',
    'pwc',
    'rabobank',
    'sbc',
    'scale-nl',
];

const envTheme = process.env.VUE_APP_THEME || 'default';

const theme: typeof themes[number] = themes.includes(envTheme) ? envTheme : 'default';

// Import the correct theme styling.
import(`@/assets/scss/themes/${theme}/index.scss`);

// Load correct favicon.
useFavicon(`${theme}-favicon.ico`, {
    baseUrl: '/favicon/',
    rel: 'icon',
});

// Load correct title.
const title = get(
    config,
    `TITLE.${theme}`,
    'Pitchy',
);

useTitle(title);

export {
    theme,
};
