import HasWebsocketMixin, {HasWebsocket} from '@/models/mixins/HasWebsocket';
import {LocationQueryRaw, RouteLocationRaw} from 'vue-router';
import Notification, {Notifications} from '@/models/Notification';
import Collection from '@/models/Collection';
import {DefaultConversions} from '@/models/Media';
import {LocationName} from '@/models/mixins/HasLocation';
import Model from '@/models/Model';
import {PusherPresenceChannel} from 'laravel-echo/src/channel/pusher-presence-channel';
import pick from 'lodash/pick';

export interface UserData {
    createdAt?: string;
    currentPassword?: string;
    deletedAt?: string;
    email: string;
    firstName: string;
    fullName: string;
    // The string is for the id 'me', which will be the current user in the backend.
    id?: number|string;
    isAdmin?: boolean;
    lastName: string;
    name: string;
    notifications?: Notifications;
    password?: string;
    phone: string;
    profilePicture?: DefaultConversions;
    quote?: string;
    unreadNotifications?: number;
    updatedAt?: string;
}

class User extends Model<UserData> {
    /**
     * @inheritdoc
     */
    endpoint = 'users';

    // Get the user's display name.
    get displayName(): string {
        return this.fullName
            || this.firstName
            || this.email;
    }

    /**
     * @inheritdoc
     */
    getDefaults(): UserData {
        return {
            name: '',
            fullName: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            unreadNotifications: 0,
        };
    }

    /**
     * @inheritdoc
     */
    getLocation(name: LocationName = 'edit', query?: LocationQueryRaw): RouteLocationRaw {
        // Edit doesn't need params, so we override the behavior.
        if (name === 'edit') {
            return {
                name: `${this.getLocationName()}.${name}`,
            };
        }

        return super.getLocation(name, query);
    }

    /**
     * @inheritdoc
     */
    getSaveData(): Partial<Record<string, any>> {
        const data: Record<string, any> = pick(
            this,
            [
                'firstName',
                'lastName',
                'email',
                'currentPassword',
                'password',
            ],
        );

        // Only if the profile picture has changed, add it to the save data.
        if (this.profilePicture?.original !== this.original.profilePicture?.original) {
            /*
             * When updating the profile picture, the API expects a string, not
             * an object of keys and strings.
             */
            data.profilePicture = this.profilePicture?.original;
        }

        return data;
    }

    /**
     * Mark all user's notifications as read.
     */
    async markAllNotificationsRead(): Promise<void> {
        // Create method is called, because it uses the needed POST request.
        await this.create({
            data: {},
            url: `/notifications/users/${this.id}/mark-all-read`,
        });

        this.notifications!.unread.each((notification: Notification) => {
            notification.readAt = new Date().toISOString();
        });

        this.unreadNotifications = 0;
    }
}

interface User extends UserData {}

export default User;

export class Users extends Collection<User> {
    /**
     * @inheritdoc
     */
    endpoint = 'users';

    /**
     * @inheritdoc
     */
    getModel(): typeof User {
        return User;
    }
}

@HasWebsocketMixin
class WsUser extends User {
    /**
     * @inheritdoc
     */
    getChannelName(): string | undefined {
        return this.id && this.id !== 'me'
            ? `User.${this.id}`
            : undefined;
    }

    /**
     * @inheritdoc
     */
    registerListeners(channel: PusherPresenceChannel): void {
        if (!this.notifications) {
            this.set('notifications', new Notifications());
        }

        channel.listen('Notifications\\NotificationCreated', (data: Record<string, any>) => {
            this.notifications!.push(new Notification(data));
        });
    }
}

interface WsUser extends HasWebsocket {}

export {WsUser};
