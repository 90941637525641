import {NavigationGuardNext, RouteLocationNormalized} from 'vue-router';
import Middleware from '@/library/Middleware';
import {WsUser} from '@/models/User';
import config from '@/library/data/config';
import useAuthStore from '@/store/auth';

export default class Auth extends Middleware {
    constructor() {
        super();

        this.name = 'Auth';
    }

    async handle(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> {
        if (await this.isAuthenticated()) {
            next();

            return;
        }

        /*
         * Here we know the user is not authenticated so we store the wanted route
         * and redirect the user to the login page.
         */
        sessionStorage.setItem(config.REDIRECT_AFTER_AUTH_KEY, JSON.stringify(to));

        next({name: 'auth.login'});
    }

    async isAuthenticated(): Promise<boolean> {
        const {isAuthenticated, me, setApiToken, setMe} = useAuthStore;

        // If the user is authenticated (has an API token), check if the user is stored.
        if (isAuthenticated.value) {
            // If the user is stored, go to the wanted route.
            if (me.value && me.value.id) {
                return true;
            }

            // The user is not stored so try to fetch it.
            const newMe = new WsUser({id: 'me'});

            try {
                await newMe.fetch();

                // Store the fetched user and go to the wanted route.
                setMe(newMe);

                return true;
            } catch {
                // If the fetch failed, the API token is invalid so we remove it.
                setApiToken(null);
            }
        }

        return false;
    }
}
