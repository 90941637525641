import {RouteRecordRaw} from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        // Once theme builder is no longer in beta, remove the leading slash.
        path: '/theme-builder',
        name: 'themeBuilder.index',
        component: () => import('@/views/themeBuilder/Index.vue'),
    },
];

export default routes;
