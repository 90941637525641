
import {PropType, defineComponent, ref} from 'vue';
import Comment from '@/models/Comment';
import Dropdown from '@/components/common/Dropdown.vue';
import {DropdownOption} from '@/components/common/DropdownMenu.vue';
import OverflowCollapse from '@/components/common/OverflowCollapse.vue';
import ProfilePicture from '@/components/users/ProfilePicture.vue';
import UpdateForm from '@/components/comments/UpdateForm.vue';
import authStore from '@/store/auth';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';

export default defineComponent({
    components: {
        Dropdown,
        OverflowCollapse,
        ProfilePicture,
        UpdateForm,
    },
    props: {
        comment: {
            type: Object as PropType<Comment>,
            required: true,
        },
    },
    emits: [
        'comment-removed',
    ],
    setup() {
        return {
            isEditing: ref<boolean>(false),
            me: authStore.me,
        };
    },
    computed: {
        isAuthor(): boolean {
            return this.comment.userId === this.me?.id;
        },
        commentOptions(): DropdownOption[] {
            return [
                {
                    key: 'delete',
                    label: this.$t('comments.actions.deleteComment'),
                    onClick: this.deleteComment,
                },
                {
                    key: 'edit',
                    label: this.$t('comments.actions.editComment'),
                    onClick: () => (this.isEditing = true),
                },
            ];
        },
        commentTimeAgo(): string {
            return this.comment.updatedAt
                ? formatDistanceToNowStrict(new Date(this.comment.updatedAt))
                : '';
        },
    },
    watch: {
        'comment.id': function handler() {
            this.isEditing = false;

            this.comment.resetAttribute('body');
        },
    },
    methods: {
        async deleteComment() {
            try {
                await this.comment.delete();
            } catch (e: any) {
                if (!e.response || !e.response.data.errors) throw e;

                return;
            }

            this.$emit('comment-removed');
        },
    },
});
