import i18n from '@/vendor/I18n';

/**
 * The structure of the object returned by `static object()` method.
 */
interface EnumObject {
    key: string | null;
    value: any;
}

/**
 * The allowed types to be used to find an enumeration.
 */
type EnumTarget = number | string | null;

export default class Enumeration {
    /**
     * An iterator for the values of the enumeration.
     */
    static [Symbol.iterator](): IterableIterator<any> {
        return this.values.values();
    }

    /**
     * The enumeration object that holds the keys and values.
     */
    static enumeration: Record<string, any> = {};

    /**
     * The key used to retrieve translations for the enumeration keys.
     */
    static translationKey = '';

    /**
     * Returns the keys of the enumeration.
     */
    static get keys(): string[] {
        return Object.keys(this.enumeration);
    }

    /**
     * Returns object structures for every value in the enumeration.
     */
    static get objects(): EnumObject[] {
        return this.keys.map((k: string) => this.object(k));
    }

    /**
     * Returns the values of the enumeration.
     */
    static get values(): any[] {
        return Object.values(this.enumeration);
    }

    /**
     * Returns the key for the specified target.
     */
    static key(target: EnumTarget): string | null {
        if (!target) {
            return null;
        }

        return this.keys.find((key: string) => {
            if (typeof target === 'string') {
                return key === target;
            }

            return this.enumeration[key] === target;
        }) || null;
    }

    /**
     * Returns the object for the specified target.
     */
    static object(target: EnumTarget): EnumObject {
        const key = this.key(target);

        return {
            key,
            value: this.value(key),
        };
    }

    /**
     * Returns the value for the specified target.
     */
    static value(target: EnumTarget): any | null {
        if (!target) {
            return null;
        }

        const result = this.values.find((value: any) => {
            if (typeof target === 'number') {
                return value === target;
            }

            return this.enumeration[target] === value;
        });

        return typeof result === 'undefined' ? null : result;
    }

    /**
     * Returns the translation for the specified target.
     */
    static translate(target: EnumTarget): string {
        const hasKey = this.key(target);

        return hasKey
            ? this.translateKey(target)
            : this.translateValue(target);
    }

    /**
     * Returns the translation for the specified key.
     */
    static translateKey(key: EnumTarget): string {
        return i18n.global.t(`enums.${this.translationKey}.${key}`);
    }

    /**
     * Returns the translation for the specified value.
     */
    static translateValue(value: number | string | null): string {
        const object = this.objects.find((obj: EnumObject) => obj.value === value);

        if (!object) return '';

        return this.translateKey(object.key);
    }
}

interface EnumConstructor {
    new(): Enumeration;
    enumeration: Record<string, any>;
}

/**
 * A decorator to auto-fill Enumeration.enumeration.
 */
function Enum(constructor: EnumConstructor): void {
    constructor.enumeration = {};

    for (const [key, value] of Object.entries(constructor)) {
        // Only allow uppercase keys and non-null values.
        if (key.toUpperCase() === key && value != null) {
            constructor.enumeration[key] = value;
        }
    }
}

export {
    Enum,
    EnumObject,
};
