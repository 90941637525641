import {camelCase, set} from 'lodash';
import {DirectiveBinding} from 'vue';
import {Tooltip} from 'bootstrap';

const getOptions = (el: HTMLElement, binding: DirectiveBinding): Tooltip.Options => {
    const modifiers: string[] = Object.keys(binding.modifiers)
        .filter((modifier: string) => ['hover', 'focus', 'click'].includes(modifier));

    const trigger = (modifiers.length ? modifiers.join(' ') : 'hover focus') as Tooltip.Options['trigger'];

    const options = {
        title: binding.value || el.getAttribute('title') || '',
        trigger,
    } as Tooltip.Options;

    Object.keys(el.dataset).forEach((key: string) => {
        set(options, camelCase(key), el.dataset[key]);
    });

    return options;
};

const destroy = (el: HTMLElement): void => {
    const tooltip = Tooltip.getInstance(el);

    if (tooltip) {
        tooltip.dispose();
    }
};

export default {
    mounted(el: HTMLElement, binding: DirectiveBinding): void {
        new Tooltip(el, getOptions(el, binding));
    },
    updated(el: HTMLElement, binding: DirectiveBinding): void {
        destroy(el);

        new Tooltip(el, getOptions(el, binding));
    },
    beforeUnmount(el: HTMLElement): void {
        destroy(el);
    },
};
