import {RouteRecordRaw} from 'vue-router';

const children: RouteRecordRaw[] = [
    {
        path: '/demo',
        name: 'demo',
        component: () => import('@/views/demo/CreateProfile.vue'),
        props: true,
    },
];

const routes: RouteRecordRaw[] = [
    {
        path: '',
        component: () => import('@/components/layouts/auth/Auth.vue'),
        children,
    },
];

export default routes;
