import Comment, {Comments} from '@/models/Comment';
import HasWebsocketMixin, {HasWebsocket} from '@/models/mixins/HasWebsocket';
import {Action} from '@/library/model-collection/providers/Provider';
import Cast from '@/library/model-collection/casts/Cast';
import Collection from '@/models/Collection';
import CommentableType from '@/library/enumerations/CommentableType';
import ConstructorCast from '@/library/model-collection/casts/ConstructorCast';
import Delta from 'quill-delta';
import Model from '@/models/Model';
import Pitch from '@/models/Pitch';
import {PusherPresenceChannel} from 'laravel-echo/src/channel/pusher-presence-channel';
import Subject from '@/models/Subject';

interface ScriptData {
    id?: number;
    pitchId?: number;
    subjectId?: number;
    body: Delta;
    index: number;
    active: boolean;
    maxWords: number;
    comments: Comments;
    createdAt?: string;
    updatedAt?: string;
    confirmedAt?: string|null;
    subject?: Subject;
    pitch?: Pitch;
    permission?: number;
}

class Script extends Model<ScriptData> {
    /**
     * @inheritdoc
     */
    endpoint = 'scripts';

    /**
     * @inheritdoc
     */
    forceEnableWebsocket = false;

    // Returns whether the script has an empty body.
    get isEmpty(): boolean {
        return this.body.ops.length <= 1
            && this.body.ops[0]?.insert === '\n';
    }

    /**
     * @inheritdoc
     */
    cancel(message?: string): void {
        this.provider.cancel(message);
    }

    /**
     * @inheritdoc
     */
    getCasts(): Partial<Record<keyof ScriptData, Cast>> {
        return {
            body: new ConstructorCast(Delta),
            subject: new ConstructorCast(Subject),
            comments: new ConstructorCast(Comments),
            pitch: new ConstructorCast(Pitch),
        };
    }

    /**
     * @inheritdoc
     */
    getDefaults(): ScriptData {
        return {
            body: new Delta(),
            index: 0,
            active: true,
            maxWords: 0,
            comments: new Comments([], {page: undefined}),
        };
    }

    /**
     * @inheritdoc
     */
    getEndpoint(action: Action): string {
        if (['delete', 'fetch', 'update'].includes(action)) {
            return `${this.endpoint}/${this.getIdentifier()}`;
        }

        return this.endpoint;
    }

    /**
     * @inheritdoc
     */
    getFetchParams(): Record<string, any> {
        return {};
    }

    /**
     * @inheritdoc
     */
    getSaveData(): Partial<ScriptData> {
        return {
            id: this.id,
            body: this.body,
            index: this.index,
            active: this.active,
            confirmedAt: this.confirmedAt,
        };
    }
}

interface Script extends ScriptData {}

export default Script;

export class Scripts extends Collection<Script> {
    /**
     * @inheritdoc
     */
    endpoint = 'scripts';

    /**
     * @inheritdoc
     */
    getModel(): typeof Script {
        return Script;
    }
}

@HasWebsocketMixin
class WsScript extends Script {
    /**
     * @inheritdoc
     */
    getChannelName(): string|undefined {
        return this.getIdentifier() && this.permission !== undefined
            ? `Script.${this.getIdentifier()}.${this.permission}`
            : undefined;
    }

    /**
     * @inheritdoc
     */
    registerListeners(channel: PusherPresenceChannel): void {
        channel.listen('Scripts\\ScriptUpdate', (data: Record<string, any>) => {
            this.fill(data);
        });

        channel.listen('Comments\\CommentUpdate', (data: Record<string, any>) => {
            const comment = new Comment(data);

            if (comment.commentableType === CommentableType.SCRIPTS) {
                this.comments.push(comment);
            } else {
                const parent = this.comments.firstWhere('id', '===', comment.commentableId);

                parent?.comments.push(comment);
            }
        });

        channel.listen('Comments\\CommentDeleted', (data: Record<string, any>) => {
            const comment = data;

            if (comment.commentableType === CommentableType.SCRIPTS) {
                this.comments.removeFirstWhere((c: Comment) => c.id === comment.id);
            } else {
                const parent = this.comments.firstWhere('id', '===', comment.commentableId);

                parent.comments.removeFirstWhere((c: Comment) => c.id === comment.id);
            }
        });

        // This gets triggered when the script's websocket channel is connected.
        channel.listenForWhisper('get-text', (data: Record<string, any>) => {
            const body = new Delta(data.ops);

            this.body = body;
            this.original.body = body;
        });

        // This gets triggered after the backend has processed the update.
        channel.listenForWhisper('ack-text', (data: Record<string, any>) => {
            const delta = new Delta(data.ops);

            this.original.body = this.original.body.compose(delta);
        });

        // This gets triggered when another user sends a text update.
        channel.listenForWhisper('text', (data: Record<string, any>) => {
            const delta = new Delta(data.ops);

            /*
             * Get the diff between original and current body.
             * This is the text that is not yet sent to the backend.
             */
            const diff = this.original.body.diff(this.body);

            // Add the update to the original body.
            this.original.body = this.original.body.compose(delta);

            /*
             * Apply an Operational Transform so the update is added to the correct location,
             * compose it with the original body and set it to the current body.
             * For more info on OT: https://en.wikipedia.org/wiki/Operational_transformation.
             */
            this.body = this.original.body.compose(delta.transform(diff, true));
        });

        /*
         * After everything is initialized, send a `get-text` event to get the latest
         * source of truth from the websocket server.
         */
        channel.whisper('get-text', {});
    }
}

interface WsScript extends HasWebsocket {}

export {WsScript};

export class WsScripts extends Collection<WsScript> {
    /**
     * @inheritdoc
     */
    endpoint = 'scripts';

    /**
     * @inheritdoc
     */
    getModel(): typeof WsScript {
        return WsScript;
    }
}
