
import {PropType, defineComponent} from 'vue';
import ResourceList from '@/components/resources/ResourceList.vue';
import ResourceType from '@/library/enumerations/ResourceType';
import {Resources} from '@/models/Resource';
import Script from '@/models/Script';

export default defineComponent({
    components: {
        ResourceList,
    },
    props: {
        script: {
            type: Object as PropType<Script>,
            required: true,
        },
    },
    computed: {
        resources(): Resources {
            return this.script
                .subject
                ?.resources
                .where('type', ResourceType.MEDIA)
                    || new Resources();
        },
    },
});
