import Cast from '@/library/model-collection/casts/Cast';
import Collection from '@/models/Collection';
import ConstructorCast from '@/library/model-collection/casts/ConstructorCast';
import {Medias} from '@/models/Media';
import Model from '@/models/Model';
import ResourceType from '@/library/enumerations/ResourceType';
import User from '@/models/User';
import addSeconds from 'date-fns/addSeconds';
import format from 'date-fns/format';

interface ResourceData {
    id?: number;
    userId?: number;
    title: string;
    body: string;
    type?: ResourceType;
    user?: User;
    media: Medias;
    resources?: Resources;
    data?: Record<string, any>;
}

class Resource extends Model<ResourceData> {
    /**
     * @inheritdoc
     */
    endpoint = 'resources';

    // Format the video media duration to a readable string.
    get formattedMediaDuration(): string {
        const date = addSeconds(new Date(0), this.mediaDuration);

        return format(date, 'mm:ss');
    }

    // Get the duration of the first video media item.
    get mediaDuration(): number {
        const videoMedia = this.media.firstWhere('mimeType', '===', 'video/mp4');

        return !videoMedia || videoMedia.duration === undefined
            ? 0
            : videoMedia.duration;
    }

    /**
     * @inheritdoc
     */
    getCasts(): Partial<Record<keyof ResourceData, Cast>> {
        return {
            user: new ConstructorCast(User),
            media: new ConstructorCast(Medias),
            resources: new ConstructorCast(Resources),
        };
    }

    /**
     * @inheritdoc
     */
    getDefaults(): ResourceData {
        return {
            title: '',
            body: '',
            media: new Medias(),
        };
    }
}

interface Resource extends ResourceData {}

export default Resource;

export class Resources extends Collection<Resource> {
    /**
     * @inheritdoc
     */
    endpoint = 'resources';

    // Format the total video media duration to a readable string.
    get formattedTotalMediaDuration(): string {
        const date = addSeconds(new Date(0), this.totalMediaDuration);

        return format(date, 'mm:ss');
    }

    // Get the total video media duration of all resources.
    get totalMediaDuration(): number {
        return this.items.reduce((durationSum: number, resource: Resource) => {
            return durationSum + resource.mediaDuration;
        }, 0);
    }

    /**
     * @inheritdoc
     */
    getModel(): typeof Resource {
        return Resource;
    }
}
