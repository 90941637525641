import {addScrollListener, removeScrollListener, updateScrollListener} from '@/library/directives/infiniteScroll';
import {DirectiveBinding} from 'vue';

export default {
    mounted(el: HTMLElement, binding: DirectiveBinding): void {
        const {collection, options} = binding.value;

        if (!collection.fetch || typeof collection.fetch !== 'function') {
            throw new Error('Infinite scroll collection directive requires a collection with a fetch function.');
        }

        addScrollListener(el, binding, () => {
            if (!collection.lastPageFetched()) {
                collection.fetch(options);
            }
        });
    },
    updated(el: HTMLElement, binding: DirectiveBinding): void {
        updateScrollListener(el, binding);
    },
    beforeUnmount(el: HTMLElement): void {
        removeScrollListener(el);
    },
};
