
import {PropType, defineComponent, ref} from 'vue';
import Resource, {Resources} from '@/models/Resource';
import MarkdownIt from 'markdown-it';
import ResourceType from '@/library/enumerations/ResourceType';
import Script from '@/models/Script';
import Tip from '@/components/resources/Tip.vue';
import {Tooltip} from 'bootstrap';

export default defineComponent({
    components: {
        Tip,
    },
    props: {
        script: {
            type: Object as PropType<Script|undefined>,
            default: undefined,
        },
    },
    setup() {
        return {
            copyButton: ref<HTMLButtonElement>(),
            tipIndex: ref<number>(0),
            tooltip: ref<Tooltip>(),
            tooltipTimer: 0,
            slideDirection: ref<string>('right-to-left'),
        };
    },
    computed: {
        tips(): Resources {
            const resources = this.script?.subject?.resources || new Resources();

            return resources.where('type', ResourceType.TIP);
        },
        currentTip(): Resource|undefined {
            return this.tips.get(this.tipIndex) || undefined;
        },
    },
    watch: {
        'script.id': function handler() {
            this.tipIndex = 0;
        },
        tipIndex() {
            if (this.tooltip) {
                this.tooltip.update();
            }
        },
    },
    mounted() {
        if (!this.copyButton) {
            return;
        }

        this.tooltip = new Tooltip(this.copyButton, {
            placement: 'top',
            title: this.$t('tips.actions.copied'),
            trigger: 'manual',
        });
    },
    methods: {
        copyTip() {
            if (!this.currentTip || !this.tooltip) {
                return;
            }

            const renderedTip = new MarkdownIt({
                html: true,
            }).render(this.currentTip.body);

            const blobTip = new Blob([renderedTip], {
                type: 'text/html',
            });

            /*
             * ESLint thinks the ClipboardItem type doesn't exist, but it does.
             * See https://developer.mozilla.org/en-US/docs/Web/API/ClipboardItem
             */
            // eslint-disable-next-line no-undef
            const clipboardTip = new ClipboardItem({
                'text/html': blobTip,
            });

            navigator.clipboard.write([clipboardTip]);

            clearTimeout(this.tooltipTimer);

            this.tooltip.show();

            this.tooltipTimer = setTimeout(() => {
                this.tooltip!.hide();
            }, 1000);
        },
        goToTip(increment: number) {
            this.slideDirection = increment > 0
                ? 'right-to-left'
                : 'left-to-right';

            this.tipIndex += increment;
        },
    },
});
