/* eslint-disable import/prefer-default-export */
import config from '@/library/data/config';
import {secondsToMinute} from '@/library/helpers/_time';

// Count the amount of words in a string.
const wordCounter = (value: string): number => value
    .trim()
    .split(/\s+/)
    .filter((word: string) => word.trim() !== '')
    .length;

// Calculate average words per second.
const wordsPerSecond = (value: number): number => value / (config.WORDS_PER_MINUTE / 60);

// Calculate average words per minute.
const wordsPerMinute = (value: number): number => secondsToMinute(wordsPerSecond(value));

export {
    wordCounter,
    wordsPerSecond,
    wordsPerMinute,
};
