import Cast from '@/library/model-collection/casts/Cast';
import Collection from '@/models/Collection';
import ConstructorCast from '@/library/model-collection/casts/ConstructorCast';
import Model from '@/models/Model';
import Pitch from '@/models/Pitch';
import Role from '@/library/enumerations/Role';
import SharePermission from '@/library/enumerations/SharePermission';
import Team from '@/models/Team';
import User from '@/models/User';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';

export interface InvitationSync {
    id?: number;
    email?: string;
    permissions?: SharePermission[];
    roles?: Role[];
}

interface InvitationData {
    id?: number;
    modelId?: number;
    modelType?: string;
    userId?: number;
    acceptedAt?: string;
    createdAt?: string;
    updatedAt?: string;
    model?: Team|Pitch;
    user?: User;
    permissions?: SharePermission[]|string;
    // These attributes are needed for the PUT request of invitations.
    detach: number[];
    sync: InvitationSync[];
    roles?: Role[];
}

class Invitation extends Model<InvitationData> {
    /**
     * @inheritdoc
     */
    endpoint = 'invitations';

    /**
     * @inheritdoc
     */
    getCasts(): Partial<Record<keyof InvitationData, Cast>> {
        const modelCast = this.modelType && this.modelType === 'pitches'
            ? Pitch
            : Team;

        return {
            model: new ConstructorCast(modelCast),
            user: new ConstructorCast(User),
        };
    }

    /**
     * @inheritdoc
     */
    getDefaults(): InvitationData {
        return {
            detach: [],
            sync: [],
        };
    }

    /**
     * @inheritdoc
     */
    getSaveData(): Partial<Record<string, any>> {
        const data = pick(
            this,
            [
                'detach',
                'sync',
            ],
        );

        return pickBy(
            data,
            ((value: any) => !isEmpty(value)),
        );
    }
}

interface Invitation extends InvitationData {}

export default Invitation;

export class Invitations extends Collection<Invitation> {
    /**
     * @inheritdoc
     */
    endpoint = 'invitations';

    /**
     * @inheritdoc
     */
    getModel(): typeof Invitation {
        return Invitation;
    }
}
