
import {PropType, defineComponent} from 'vue';
import Comment from '@/models/Comment';
import Form from '@/components/comments/Form.vue';

export default defineComponent({
    components: {
        Form,
    },
    props: {
        comment: {
            type: Object as PropType<Comment>,
            required: true,
        },
    },
    emits: [
        'submit',
    ],
    mounted() {
        this.comment.resetStatus();
    },
    methods: {
        cancelUpdate() {
            this.comment.resetAttribute('body');
        },
        async updateComment() {
            this.$emit('submit');
        },
    },
});
