import {NavigationGuardNext, RouteLocationNormalized} from 'vue-router';
import Middleware from '@/library/Middleware';
import TeamType from '@/library/enumerations/TeamType';
import userTeamsStore from '@/store/userTeams';

export default class NeedsTeam extends Middleware {
    constructor() {
        super();

        this.name = 'NeedsTeam';
    }

    async handle(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> {
        const {currentTeam, fetchTeams} = userTeamsStore;

        if (!currentTeam.value) {
            await fetchTeams();
        }

        const teamType = currentTeam.value != null
            ? currentTeam.value!.type
            : TeamType.TEAM;

        to.params.teamType = teamType.toString();

        next();
    }
}
