import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    "force-show-buttons": "",
    "input-class": ['p-1', {'is-invalid': _ctx.comment.error}],
    "reference-comment": _ctx.comment,
    onCancel: _ctx.cancelUpdate,
    onSubmit: _ctx.updateComment
  }, null, 8, ["input-class", "reference-comment", "onCancel", "onSubmit"]))
}