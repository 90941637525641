import {NavigationGuardNext, RouteLocationNormalized} from 'vue-router';
import Middleware from '@/library/Middleware';
import useAuthStore from '@/store/auth';

export default class Guest extends Middleware {
    constructor() {
        super();

        this.name = 'Guest';
    }

    handle(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
        const authStore = useAuthStore;

        if (authStore.isAuthenticated.value) {
            next({name: 'home'});

            return;
        }

        next();
    }
}
